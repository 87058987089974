import React from 'react';
import PropTypes from 'prop-types';
import isempty from 'lodash.isempty';
import AwardIcon from 'components/AwardIcon';
import { Section, sectionPropTypes, sectionDefaultProps } from 'views/Profile/Sections';
import { AWARDS_AND_BADGES_SECTION_ID } from 'constants/Profiles';
import { Box } from '@mui/material';

export const AwardsAndBadges = ({ awards, ...rest }) => {
  if (isempty(awards)) return null;

  return (
    <Section
      {...rest}
      sectionName={AWARDS_AND_BADGES_SECTION_ID}
      right={
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            width: '100%',
            maxWidth: '100%',
            gap: { xs: 2, md: 4 },
            justifyContent: 'left',
          }}
        >
          {awards.map((award) => (
            <AwardIcon key={award.name} award={award} />
          ))}
        </Box>
      }
    />
  );
};

AwardsAndBadges.propTypes = {
  ...sectionPropTypes,
  awards: PropTypes.arrayOf(
    PropTypes.shape({
      year: PropTypes.string,
      name: PropTypes.string,
      imageIdentifier: PropTypes.string.isRequired,
      altText: PropTypes.string,
      howEarned: PropTypes.string,
      svgUrl: PropTypes.string.isRequired,
    })
  ),
};

AwardsAndBadges.defaultProps = {
  ...sectionDefaultProps,
  awards: [],
};

export default AwardsAndBadges;
