import { useEffect } from 'react';

import measureNavigation from 'helpers/measureNavigation';

export default function usePageEffects() {
  useEffect(() => {
    measureNavigation();

    if (window.initPinterestWidgets) window.initPinterestWidgets();
  }, []);
}
