import React from 'react';
import { DollarCircleIcon } from 'components/Icons';
import { getStartingPrice } from 'helpers/vendor';

const getProfileStartingAtPriceRangeDetails = profile => {
  const { showPriceRange, payRateLow, timePeriod } = profile;
  if (!showPriceRange) return null;

  const icon = iconProps => <DollarCircleIcon {...iconProps} />;
  const detail = () => (
    <>
      Starting at <b>${getStartingPrice(payRateLow, true, timePeriod)}</b>
    </>
  );
  return { icon, detail };
};

export default getProfileStartingAtPriceRangeDetails;
