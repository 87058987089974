import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@tkww/the-bash-frontend';
import { Box, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import sxPropType from 'propTypes/sx';

const titleVariants = ['h1', 'h2'];

export const sectionPropTypes = {
  left: PropTypes.node,
  right: PropTypes.node,
  subTitle: PropTypes.string,
  title: PropTypes.string,
  titleCaption: PropTypes.string,
  titleVariant: PropTypes.oneOf(titleVariants),
};

export const sectionDefaultProps = {
  left: null,
  right: null,
  subTitle: null,
  title: null,
  titleCaption: null,
  titleVariant: 'h2',
};

const StyledTypography = styled(Typography)(({ theme }) => ({
  textTransform: 'none',
  margin: '0 0 16px',
  color: theme.palette.text.primary,
}));

const Section = ({
  left,
  right,
  sectionName,
  subTitle,
  title,
  titleCaption,
  titleVariant,
  sxTitle,
  sxSubtitle,
}) => {
  const Left = left;
  const Right = right;

  return (
    <Box
      sx={(theme) => ({
        overflowWrap: 'break-word',
        marginBottom: 7, //  40 + 20 + 20 == 56 + 12 + 12 of previous non global spacing,
      })}
      role="region"
      aria-labelledby={sectionName}
    >
      <Grid
        container
        spacing={0}
        sx={(theme) => ({
          marginLeft: 0,
          marginRight: 0,
          '& > div:first-child': {
            padding: '12px 20px 12px 0',
            [theme.breakpoints.down('md')]: {
              paddingTop: 0,
              paddingRight: 0,
            },
          },
          '& > div:last-child': {
            padding: '12px 0 12px 20px',
            [theme.breakpoints.down('md')]: {
              paddingLeft: 0,
            },
          },
        })}
      >
        <Grid item xs={12} md={4}>
          <StyledTypography variant={titleVariant} id={sectionName} sx={sxTitle}>
            {title}&nbsp;
            {titleCaption && (
              <Typography sx={{ mt: 2, display: 'block' }} variant="body1" component="span">
                {titleCaption}
              </Typography>
            )}
          </StyledTypography>
          <StyledTypography variant="body1" sx={sxSubtitle}>
            {subTitle}
          </StyledTypography>
          {Left && Left}
        </Grid>
        <Grid item xs={12} md={8}>
          {Right && Right}
        </Grid>
      </Grid>
    </Box>
  );
};

Section.propTypes = {
  ...sectionPropTypes,
  sectionName: PropTypes.string.isRequired,
  sxTitle: sxPropType,
  sxSubtitle: sxPropType,
};

Section.defaultProps = {
  ...sectionDefaultProps,
  sxTitle: {},
  sxSubtitle: {},
};

export default Section;
