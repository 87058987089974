import React from 'react';
import { VerifiedIcon } from 'components/Icons';

const getProfileBookingsDetails = profile => {
  const { verifiedBookingsCount } = profile;

  if (verifiedBookingsCount > 0) {
    const icon = iconProps => <VerifiedIcon {...iconProps} />;
    const detail = () => (
      <>
        <b>{verifiedBookingsCount}</b> Verified Booking{verifiedBookingsCount > 1 ? 's' : ''}
      </>
    );
    return { icon, detail };
  }
  return null;
};

export default getProfileBookingsDetails;
