import React, { useCallback, useEffect, useMemo } from 'react';
import { Typography } from '@tkww/the-bash-frontend';

import { REQUEST_QUOTE_TEXT } from 'constants/Vendors';
import { gmConfig } from 'helpers/config';
import { profilePropTypes, profileDefaultProps } from 'propTypes/profile';
import getProfileBreadCrumbs from 'helpers/profiles/getProfileBreadcrumbs';
import { VendorStickyFooter } from 'components/Profile/VendorStickyFooter';
import { ProfileLayout } from 'views/Profile';
import { VendorDetailMemo } from 'views/Profile/VendorProfilePage';
import {
  AboutSection,
  SummarySection,
  ProvidedServicesSection,
  AwardsAndBadgesSection,
  SongListSection,
  ReviewsSection,
  RelatedProfile,
  AvailabilitySection,
  BookedEventsSection,
} from 'views/Profile/Sections';
import {
  ABOUT_SECTION_VENDOR_TITLE,
  ABOUT_SECTION_VENDOR_SUBTITLE,
  AWARDS_AND_BADGES_SECTION_TITLE,
  AWARDS_AND_BADGES_SECTION_SUBTITLE,
  BOOKED_EVENTS_SECTION_TITLE,
  BOOKED_EVENTS_SECTION_ID,
  PROVIDED_SERVICES_SECTION_VENDOR_TITLE,
  PROVIDED_SERVICES_SECTION_VENDOR_SUBTITLE,
  RELATED_PROFILES_SECTION_TITLE,
  RELATED_PROFILES_SECTION_SUBTITLE,
  REVIEWS_SECTION_TITLE,
  REVIEWS_SECTION_SUBTITLE,
  SONGS_SECTION_TITLE,
  SONGS_SECTION_SUBTITLE,
  AVAILABILITY_SECTION_TITLE,
  AVAILABILITY_SECTION_SUBTITLE,
  AVAILABILITY_SECTION_ID,
} from 'constants/Profiles';
import jump from 'helpers/jump';
import { useDispatch } from 'react-redux';
import { profileContactVendorClick, profileTabClick } from 'state/modules/analytics';
import { PROFILE_SUMMARY_CLICK_AREA, STICKY_BAR_CLICK_AREA } from 'analytics/constants';
import LazyLoad from 'react-lazyload';
import dynamic from 'next/dynamic';

const VerifiedIcon = dynamic(
  () => import('components/Icons').then((module) => module.VerifiedIcon),
  { ssr: false }
);

export const VendorProfilePage = (props) => {
  const { profile } = props;
  const hasEvents = profile.upcomingEventTotal > 0 || profile.pastEventTotal > 0;
  const dispatch = useDispatch();

  const trackProfileTabClick = useCallback(
    (tab) => {
      dispatch(profileTabClick(profile, tab));
    },
    [profile]
  );

  const handleContactClick = useCallback(
    (e, source) => {
      dispatch(profileContactVendorClick({ clickArea: source, vendor: profile }));
      e.preventDefault();
      e.stopPropagation();
      window.location.assign(`https://${gmConfig.domains.www}/requestquote?memberId=${profile.id}`);
    },
    [profile]
  );

  const SummaryComponent = React.memo(
    React.forwardRef(
      /* eslint-disable prefer-arrow-callback */
      function VendorSummary(summaryProps, ref) {
        return (
          <SummarySection
            ref={ref}
            {...summaryProps}
            contactCtaText={REQUEST_QUOTE_TEXT}
            details={<VendorDetailMemo profile={profile} />}
            profile={profile}
            onContactClick={(e) => handleContactClick(e, PROFILE_SUMMARY_CLICK_AREA)}
          />
        );
      }
    )
  );

  const StickyFooterComponent = React.memo((footerProps) => (
    <VendorStickyFooter
      {...footerProps}
      profile={profile}
      onClick={(e) => handleContactClick(e, STICKY_BAR_CLICK_AREA)}
    />
  ));

  useEffect(() => {
    if (typeof window === 'undefined') return;
    if (window.location.hash !== `#${BOOKED_EVENTS_SECTION_ID}`) return;
    if (!hasEvents) jump(`#${AVAILABILITY_SECTION_ID}`);
  }, [hasEvents]);

  const breadcrumbs = useMemo(() => getProfileBreadCrumbs(profile), [profile]);

  return (
    <ProfileLayout
      {...props}
      breadcrumbs={breadcrumbs}
      contactCtaText={REQUEST_QUOTE_TEXT}
      onCheckAvailability={() => {}}
      summary={SummaryComponent}
      stickyFooter={StickyFooterComponent}
      trackProfileTabClick={trackProfileTabClick}
    >
      <AboutSection
        profile={profile}
        title={ABOUT_SECTION_VENDOR_TITLE}
        subTitle={ABOUT_SECTION_VENDOR_SUBTITLE}
      />
      <ProvidedServicesSection
        profile={profile}
        title={PROVIDED_SERVICES_SECTION_VENDOR_TITLE}
        subTitle={PROVIDED_SERVICES_SECTION_VENDOR_SUBTITLE}
      />
      <ReviewsSection
        profile={profile}
        title={REVIEWS_SECTION_TITLE}
        subTitle={REVIEWS_SECTION_SUBTITLE}
      />
      <AwardsAndBadgesSection
        awards={profile.awards}
        title={AWARDS_AND_BADGES_SECTION_TITLE}
        subTitle={AWARDS_AND_BADGES_SECTION_SUBTITLE}
      />
      <SongListSection
        songList={profile.songList}
        title={SONGS_SECTION_TITLE}
        subTitle={SONGS_SECTION_SUBTITLE}
      />
      <LazyLoad>
        {/* Lazy loading this section to optimize initial render since the calendar provides no SEO value */}
        <AvailabilitySection
          profile={profile}
          title={AVAILABILITY_SECTION_TITLE}
          subTitle={AVAILABILITY_SECTION_SUBTITLE}
          left={<Typography variant="body1">&bull; : Booked Events</Typography>}
        />
      </LazyLoad>
      <BookedEventsSection
        profile={profile}
        title={BOOKED_EVENTS_SECTION_TITLE}
        left={
          <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center' }}>
            <VerifiedIcon />: The Bash Verified Bookings
          </Typography>
        }
      />
      <RelatedProfile
        title={RELATED_PROFILES_SECTION_TITLE}
        subTitle={RELATED_PROFILES_SECTION_SUBTITLE}
        profile={profile}
      />
    </ProfileLayout>
  );
};

VendorProfilePage.propTypes = profilePropTypes;
VendorProfilePage.defaultProps = profileDefaultProps;

export default VendorProfilePage;
