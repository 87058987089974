import React from 'react';
import PropTypes from 'prop-types';
import ReviewCard from './ReviewCard';

const ReviewCards = ({ reviews, profile }) => {
  return (
    <>
      {reviews.map((review) => (
        <ReviewCard key={review.id} review={review} profile={profile} sx={{ marginBottom: 5 }} />
      ))}
    </>
  );
};

export default ReviewCards;

ReviewCards.propTypes = {
  reviews: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  profile: PropTypes.shape({}).isRequired,
};
