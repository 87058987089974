import React from 'react';
import StickyFooter from 'components/Profile/StickyFooter';
import { REQUEST_QUOTE_TEXT } from 'constants/Vendors';
import { StarRating } from 'components/VendorRating';
import { getAverageRating, getNumberOfReviews } from 'helpers/vendor';

const VendorStickyFooter = React.forwardRef((props, ref) => {
  const { profile, ...rest } = props;
  const { rating, numberOfReviews } = profile;

  const shouldShowStarRating = rating > 0;
  const shouldShowReviews = numberOfReviews > 0;
  return (
    <StickyFooter
      ref={ref}
      {...rest}
      profile={profile}
      contactCtaText={REQUEST_QUOTE_TEXT}
      details={
        <>
          {shouldShowStarRating && (
            <>
              <StarRating stars={rating} sx={{ marginRight: '11px' }} />
              <b>
                {getAverageRating(rating, true)}
                {shouldShowReviews && (
                  <>
                    &nbsp;&#124;&nbsp;
                    {getNumberOfReviews(numberOfReviews)}
                  </>
                )}
              </b>
            </>
          )}
        </>
      }
    />
  );
});

export default VendorStickyFooter;
