/*
 * What the hell is going on here?
 *
 * This component renders a CTA bar that sticks to the bottom of the window when both:
 * 1) The user has scrolled past the main CTA (in the details section)
 * 2) The footer hasn't scrolled into view
 */
import PropTypes from 'prop-types';
import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { useTheme, useMediaQuery, Box } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Container from '@mui/material/Container';
import { Typography, Button } from '@tkww/the-bash-frontend';

import { dropShadow } from 'styles/classes';
import childrenPropTypes from 'propTypes/children';
import getResizeImageUrl from 'helpers/media/getResizeImageUrl';
import { FALLBACK_SEARCH_THUMBNAIL_URL } from 'constants/Search';
import useNodeDimensions from 'hooks/useNodeDimensions';

const HEIGHT = 74;
const THUMBNAIL_SIZE = 45;

export const StickyFooter = ({
  contactCtaText,
  onClick,
  alwaysStick,
  details,
  ctaRef,
  profile: { profileName, timePeriod, payRateLow: startingPrice, thumbnailUrl: imageSrc },
}) => {
  const stickyFooterRef = useRef(null);
  const { y: stickyFooterYDimension } = useNodeDimensions(stickyFooterRef);
  const { y: ctaYDimension } = useNodeDimensions(ctaRef);
  const [shouldStick, setShouldStick] = useState(false);
  const theme = useTheme();
  const showStartingPrice = useMediaQuery(theme.breakpoints.up('sm'));
  const showProfileInformation = useMediaQuery(theme.breakpoints.up('lg'));
  let avatarUrl = getResizeImageUrl(imageSrc, THUMBNAIL_SIZE, THUMBNAIL_SIZE);
  if (!avatarUrl) {
    avatarUrl = getResizeImageUrl(FALLBACK_SEARCH_THUMBNAIL_URL, THUMBNAIL_SIZE, THUMBNAIL_SIZE);
  }

  useEffect(() => {
    const isAboveFooter = window.innerHeight < HEIGHT + stickyFooterYDimension;
    const hasFooterDimensions = !!stickyFooterYDimension;
    const hasCtaDimensions = !!ctaYDimension;

    if (alwaysStick) {
      setShouldStick(isAboveFooter || !hasFooterDimensions);
    } else if (hasCtaDimensions && ctaYDimension > 0) {
      setShouldStick(false);
    } else if (hasFooterDimensions) {
      setShouldStick(isAboveFooter);
    }
  }, [stickyFooterYDimension, alwaysStick, ctaYDimension]);

  return (
    <Box
      ref={stickyFooterRef}
      data-testid="stickyFooter"
      sx={(theme) => ({
        height: HEIGHT,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        backgroundColor: theme.palette.blue[100],
        '& .stick': {
          ...dropShadow,
          position: 'fixed',
          bottom: 0,
          backgroundColor: theme.palette.blue[100],
          left: 0,
          right: 0,
          zIndex: 1,
        },
      })}
    >
      <Box className={classNames(shouldStick && 'stick')}>
        <Container maxWidth="xl">
          <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: '10px 0' }}>
            {showProfileInformation && (
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'flex-start',
                  paddingLeft: { sm: 0 },
                  paddingRight: { sm: 2 },
                }}
              >
                <Avatar
                  sx={{ width: THUMBNAIL_SIZE, height: THUMBNAIL_SIZE }}
                  src={avatarUrl}
                  alt={`${profileName} - Footer`}
                />
                <Box
                  component="section"
                  sx={{ margin: '0 0 0 12px', maxHeight: 58, overflow: 'hidden' }}
                >
                  <Typography
                    variant="h3"
                    sx={(theme) => ({
                      fontSize: 20,
                      fontWeight: theme.fontWeights.regular,
                      margin: 0,
                    })}
                  >
                    {profileName}
                  </Typography>
                  <Box
                    sx={{
                      margin: 0,
                      display: 'flex',
                      alignItems: 'center',
                      fontSize: 12,
                      textTransform: 'uppercase',
                    }}
                  >
                    {details}
                  </Box>
                </Box>
              </Box>
            )}
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: { xs: 'space-between', lg: 'flex-end' },
                width: { xs: '100%', lg: 'auto' },
                whiteSpace: { lg: 'nowrap' },
              }}
            >
              {!!startingPrice && showStartingPrice && (
                <Typography
                  variant="body1"
                  data-testid="starting-price"
                  sx={{ fontSize: 16, margin: '0 14px 0 0', textAlign: { lg: 'right' } }}
                >
                  Starting at <br />
                  <b>
                    ${startingPrice.toLocaleString()} {timePeriod}
                  </b>
                </Typography>
              )}
              <Button
                sx={{
                  height: 52,
                  width: { xs: '100%', md: 'auto' },
                  whiteSpace: { sm: 'nowrap' },
                  padding: { sm: '6px 0', md: '6px 22px' },
                }}
                onClick={onClick}
              >
                {contactCtaText}
              </Button>
            </Box>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

StickyFooter.propTypes = {
  contactCtaText: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  alwaysStick: PropTypes.bool.isRequired,
  profile: PropTypes.shape({}).isRequired,
  details: childrenPropTypes.isRequired,
  ctaRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.any })])
    .isRequired,
};

export default StickyFooter;
