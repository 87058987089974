/* eslint jsx-a11y/click-events-have-key-events:0 */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import { Box } from '@mui/material';

const handleClick = (e, isDisabled, onClick) => {
  if (isDisabled) {
    e.preventDefault();
  } else {
    onClick(e);
  }
};

const LinkWrapper = ({
  as,
  'data-testid': dataTestId,
  href,
  children,
  className,
  onClick,
  target,
  testid,
  qa,
  isDisabled,
  ...other
}) => (
  <Link as={as} href={href} testid={testid || dataTestId} legacyBehavior>
    <a
      className={className}
      onClick={(e) => handleClick(e, isDisabled, onClick)}
      onKeyPress={(e) => handleClick(e, isDisabled, onClick)}
      data-testid={testid || dataTestId}
      data-qa={qa}
      target={target}
      disabled={isDisabled}
      {...other}
    >
      {children}
    </a>
  </Link>
);

LinkWrapper.propTypes = {
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  'data-testid': PropTypes.string,
  href: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]).isRequired,
  isDisabled: PropTypes.bool,
  onClick: PropTypes.func,
  qa: PropTypes.string,
  target: PropTypes.string,
  testid: PropTypes.string,
};

LinkWrapper.defaultProps = {
  as: '',
  className: '',
  'data-testid': null,
  isDisabled: false,
  onClick: () => {},
  qa: null,
  target: '_self',
  testid: '',
};

export default LinkWrapper;
