import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid } from '@mui/material';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import isEmpty from 'lodash.isempty';

import { VerifiedIcon } from 'components/Icons';
import { Typography } from '@tkww/the-bash-frontend';

const EventCard = ({ eventType, startDate, endDate, city, state, venue, isVerifiedBooking }) => {
  const eventName = !isEmpty(eventType) ? eventType : venue;
  const eventStart = parseISO(startDate);
  const eventEnd = parseISO(endDate);

  return (
    <>
      <Grid item sm={12} md={6} sx={{ mb: { xs: 0, md: 1 } }}>
        <Box
          data-testid="event-card-header"
          sx={{ display: 'flex', alignItems: 'center', marginBottom: 0.5 }}
        >
          {isVerifiedBooking && (
            <VerifiedIcon
              data-testid="event-card-verified-icon"
              sx={{ mr: 1 }}
              alt="The Bash Verified Event"
            />
          )}
          <Box
            sx={(theme) => ({
              ...theme.fontSizes[16],
              fontFamily: theme.fonts.semibold,
              display: 'flex',
              flexWrap: 'wrap',
            })}
          >
            <Box component="span" sx={{ display: 'flex', alignItems: 'center' }}>
              {format(eventStart, 'MMMM d, yyyy')} &bull;&nbsp;
            </Box>
            <span>{eventName}</span>
          </Box>
        </Box>
        <Typography variant="small" sx={{ display: 'flex', alignItems: 'center' }}>
          {format(eventStart, 'E')} &bull; {format(eventStart, 'h:mm a')} -{' '}
          {format(eventEnd, 'h:mm a')}{' '}
          {city && state && (
            <>
              &bull; {city}, {state}
            </>
          )}
        </Typography>
      </Grid>
    </>
  );
};

EventCard.propTypes = {
  eventType: PropTypes.string,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  city: PropTypes.string,
  state: PropTypes.string,
  venue: PropTypes.string,
  isVerifiedBooking: PropTypes.bool.isRequired,
};

EventCard.defaultProps = {
  eventType: '',
  city: '',
  state: '',
  venue: '',
};

export default EventCard;
