import PropTypes from 'prop-types';
import React from 'react';
import { Box } from '@mui/material';
import childrenPropType from 'propTypes/children';

export const MediaLayout = ({ children, label }) => (
  <Box pt={3.75} role="region" aria-label={label}>
    {children}
  </Box>
);

MediaLayout.propTypes = {
  children: childrenPropType,
  label: PropTypes.string.isRequired,
};

MediaLayout.defaultProps = {
  children: null,
};

export default MediaLayout;
