import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash.isempty';
import { VirtualEventsIcon } from '@tkww/the-bash-frontend';

import { ClockIcon, CreditCardIcon, TrophyIcon } from 'components/Icons';
import {
  AWARDS_AND_BADGES_SECTION_ID,
  AWARDS_AND_BADGES_SECTION_TITLE,
  BOOKED_EVENTS_SECTION_ID,
  BOOKED_EVENTS_SECTION_TITLE,
  REVIEWS_SECTION_ID,
  REVIEWS_SECTION_TITLE,
} from 'constants/Profiles';
import { ProfileDetails } from 'views/Profile';
import {
  getProfileReviewsDetails,
  getProfileBookingsDetails,
  getProfileStartingAtPriceRangeDetails,
} from 'helpers/vendor/details';

const VendorDetails = ({ profile }) => {
  const { dateAdded, awards, hasActiveGigPayAccount, includeInVirtualEvents } = profile;
  const getDetails = () => {
    const details = [];

    const reviewDetails = getProfileReviewsDetails(profile, { mr: 1.5 });
    if (!isEmpty(reviewDetails)) {
      details.push({
        ...reviewDetails,
        jumpTo: REVIEWS_SECTION_ID,
        title: REVIEWS_SECTION_TITLE,
      });
    }

    const profileBookingsDetails = getProfileBookingsDetails(profile);
    if (!isEmpty(profileBookingsDetails)) {
      details.push({
        ...profileBookingsDetails,
        jumpTo: BOOKED_EVENTS_SECTION_ID,
        title: BOOKED_EVENTS_SECTION_TITLE,
      });
    }

    if (dateAdded) {
      const icon = (iconProps) => <ClockIcon {...iconProps} />;
      const detail = () => (
        <>
          Member Since <b>{dateAdded.substring(0, 4)}</b>
        </>
      );
      details.push({ icon, detail, title: 'Member Since' });
    }

    if (awards.length > 0) {
      const icon = (iconProps) => <TrophyIcon {...iconProps} />;
      const detail = () => (
        <>
          <b>{awards.length}</b> {awards.length > 1 ? 'Awards & Badges' : 'Award or Badge'}
        </>
      );
      details.push({
        icon,
        detail,
        jumpTo: AWARDS_AND_BADGES_SECTION_ID,
        title: AWARDS_AND_BADGES_SECTION_TITLE,
      });
    }

    if (includeInVirtualEvents) {
      const icon = (iconProps) => <VirtualEventsIcon {...iconProps} />;
      const detail = () => <>Virtual Services Offered</>;
      details.push({ icon, detail, title: 'Virtual Services Offered' });
    }

    if (hasActiveGigPayAccount) {
      const icon = (iconProps) => <CreditCardIcon {...iconProps} />;
      const detail = () => <>Accepts Online Payment</>;
      details.push({ icon, detail, title: 'Accepts Online Payments' });
    }

    const startingAtPriceDetails = getProfileStartingAtPriceRangeDetails(profile);
    if (!isEmpty(startingAtPriceDetails)) {
      details.push(startingAtPriceDetails);
    }

    return details;
  };

  return <ProfileDetails details={getDetails()} />;
};

VendorDetails.propTypes = {
  profile: PropTypes.shape({}).isRequired,
};

export const VendorDetailMemo = React.memo(
  VendorDetails,
  (prevProps, nextProps) => prevProps.profile.rating !== nextProps.profile.rating
);

export default VendorDetails;
