const urlToString = ({ pathname, query }, keepFalseyValues = true) => {
  const queryString = Object.entries(query)
    .filter(([key]) => Object.prototype.hasOwnProperty.call(query, key))
    .filter(([, value]) => keepFalseyValues || value)
    .map(([key, value]) => encodeURI(`${key}=${value}`))
    .join('&');
  const connector = queryString ? '?' : '';
  return `${pathname}${connector}${queryString}`;
};

export default urlToString;
