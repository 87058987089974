import PropTypes from 'prop-types';

export default PropTypes.shape({
  id: PropTypes.number.isRequired,
  parentId: PropTypes.number,
  title: PropTypes.string,
  pluralTitle: PropTypes.string,
  conceptScheme: PropTypes.string,
  slug: PropTypes.string.isRequired,
});
