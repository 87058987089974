import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useDispatch } from 'react-redux';
import { useAmp } from 'next/amp';
import { Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import { styled } from '@mui/material/styles';

import SocialIcon from 'components/SocialShare/SocialIcon';
import { socialShareCtaClick } from 'state/modules/analytics';
import sxPropType from 'propTypes/sx';

const StyledIcon = styled(Box)(({ theme }) => ({
  cursor: 'pointer',
  display: 'inline-block',
  marginRight: theme.spacing(2),
  '&:last-child': {
    marginRight: 0,
  },
}));

const SocialShare = (props) => {
  const { color, description, imageSrc, url, sx } = props;
  const dispatch = useDispatch();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const isAmpPage = useAmp();

  const openPinterestDialog = () => {
    if (typeof global.PinUtils !== 'undefined') {
      global.PinUtils.pinOne({
        url,
        media: imageSrc,
        description,
      });
    }
  };

  const onPinterestClick = () => {
    openPinterestDialog();
    dispatch(socialShareCtaClick('Pinterest'));
  };

  const onPinterestKey = (e) => {
    if (e.keyCode === 13) {
      onPinterestClick();
    }
  };

  const onFacebookClick = () => {
    dispatch(socialShareCtaClick('Facebook'));
  };

  const onCopyClick = () => {
    setSnackbarOpen(true);
    dispatch(socialShareCtaClick('Copy Link'));
  };

  return (
    <>
      <Box sx={sx} data-testid="social-share">
        {/* Pinterest widget not included for amp pages */}
        {!isAmpPage && (
          <StyledIcon
            component="a"
            className="test-pinterest"
            role="link"
            tabIndex={0}
            onKeyDown={onPinterestKey}
            onClick={onPinterestClick}
            aria-label="Share on Pinterest"
          >
            <SocialIcon type="pinterest" color={color} />
          </StyledIcon>
        )}

        <StyledIcon
          component="a"
          href={`http://www.facebook.com/sharer/sharer.php?u=${encodeURI(url)}`}
          target="_blank"
          rel="nofollow noopener noreferrer"
          className="test-facebook"
          onClick={onFacebookClick}
          aria-label="Share on Facebook"
        >
          <SocialIcon type="facebook-white" color={color} />
        </StyledIcon>

        {/* CopyToClipboard not supported on amp pages */}
        {!isAmpPage && (
          <CopyToClipboard text={url} onCopy={onCopyClick}>
            <StyledIcon component="span">
              <SocialIcon type="link" color={color} />
            </StyledIcon>
          </CopyToClipboard>
        )}
      </Box>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        message={<span id="message-id">Copied to clipboard</span>}
        action={[
          <IconButton
            key="close"
            aria-label="close"
            color="inherit"
            onClick={() => setSnackbarOpen(false)}
            size="large"
          >
            <CloseIcon />
          </IconButton>,
        ]}
      />
    </>
  );
};

SocialShare.propTypes = {
  className: PropTypes.string,
  color: PropTypes.oneOf(['white', 'blue']),
  description: PropTypes.string.isRequired,
  imageSrc: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  sx: sxPropType,
};

SocialShare.defaultProps = {
  className: '',
  color: 'white',
  sx: {},
};

export default SocialShare;
