import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash.isempty';
import { useDispatch } from 'react-redux';

import { Box, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Button, Loader, Typography } from '@tkww/the-bash-frontend';

import EventCard from 'views/Profile/Sections/BookedEvents/EventCard';

const StyledButton = styled(Button)({
  display: 'flex',
  textTransform: 'uppercase',
  margin: '12px auto 0',
});

const EventSection = ({ title, events, totalEvents, onClick, isLoading, noEventsText }) => {
  const dispatch = useDispatch();

  const showLoadMoreLink = events?.length < totalEvents && !isLoading;

  if (isEmpty(events) && isEmpty(noEventsText)) return null;

  return (
    <Box
      mb={4}
      data-testid="event-section"
      sx={{ '& .loader': { margin: '30px auto', fontSize: 4, height: 4, width: 4 } }}
    >
      <Typography variant="h3" mt={0}>
        {title}
      </Typography>
      {isEmpty(events) && <>{noEventsText}</>}
      {!isEmpty(events) && (
        <Grid container spacing={3}>
          {events.map((calendarEvent) => (
            <EventCard
              key={calendarEvent.eventDate}
              eventType={calendarEvent.categoryName}
              startDate={calendarEvent.eventDate}
              endDate={calendarEvent.endDate}
              city={calendarEvent.city}
              state={calendarEvent.state}
              venue={calendarEvent.venue}
              isVerifiedBooking={calendarEvent.isAGigMastersEvent}
            />
          ))}
        </Grid>
      )}
      {isLoading && <Loader classes={{ loader: 'loader' }} />}
      {showLoadMoreLink && (
        <StyledButton
          data-testid="load-more-events-link"
          type="tertiary"
          onClick={() => dispatch(onClick())}
        >
          Load More
        </StyledButton>
      )}
    </Box>
  );
};

EventSection.propTypes = {
  title: PropTypes.string.isRequired,
  events: PropTypes.arrayOf(PropTypes.shape({})),
  totalEvents: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  noEventsText: PropTypes.string,
};

EventSection.defaultProps = {
  events: [],
  noEventsText: '',
};

export default EventSection;
