import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import isempty from 'lodash.isempty';

import { PROVIDED_SERVICES_SECTION_ID } from 'constants/Profiles';
import { Section, sectionPropTypes, sectionDefaultProps } from 'views/Profile/Sections';
import Tag from 'components/Tag';
import { vendorServiceTagClick } from 'state/modules/analytics/profile';

export const ProvidedServices = ({ profile, ...rest }) => {
  const dispatch = useDispatch();
  if (isempty(profile.providedServiceTypes)) return null;

  const handleOnClick = (serviceTypeName) => {
    dispatch(vendorServiceTagClick(serviceTypeName));
  };

  const {
    providedServiceTypes,
    primarySearchPageLookupInfo: {
      location: { fileName: primaryLocationFileName },
    },
  } = profile;

  return (
    <Section
      {...rest}
      sectionName={PROVIDED_SERVICES_SECTION_ID}
      right={providedServiceTypes.map((serviceType) => (
        <Tag
          key={serviceType.name}
          name={serviceType.personForm}
          path={`/search/${serviceType.fileName}-${primaryLocationFileName}`}
          onClick={() => handleOnClick(serviceType.personForm)}
          sx={{ margin: '0 8px 8px 0' }}
        />
      ))}
    />
  );
};

ProvidedServices.propTypes = {
  ...sectionPropTypes,
  profile: PropTypes.shape({}).isRequired,
};

ProvidedServices.defaultProps = {
  ...sectionDefaultProps,
};

export default ProvidedServices;
