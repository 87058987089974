import urlToString from 'helpers/url/urlToString';
import { getNextRedirect } from 'helpers/redirects';

const checkForProfileRedirect = (profile, subPageSlug = null, query = {}) => {
  if (!profile || !profile.redirectUrl) {
    return null;
  }

  let url = profile.redirectUrl;
  if (subPageSlug && profile.memberLevel !== -9) {
    url += `/${subPageSlug}`;
  }

  return getNextRedirect(urlToString({ pathname: url, query }), true);
};

export default checkForProfileRedirect;
