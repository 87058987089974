import getProfileType from 'helpers/profiles/getProfileType';
import { VENDOR_PROFILE, VENUE_PROFILE } from 'constants/Profiles';

const getProfileServiceTypeAndLocationText = profile => {
  const profileType = getProfileType(profile);

  switch (profileType) {
    case VENDOR_PROFILE:
      return `${profile.primaryServiceType.personForm} from ${profile.city.displayName}`;
    case VENUE_PROFILE:
      return `${profile.primaryServiceType.personForm} in ${profile.city.displayName}`;
    default:
      return '';
  }
};

export default getProfileServiceTypeAndLocationText;
