import { END } from 'redux-saga';

/*
  This helper is meant to be called when you need to ensure that getInitialProps is able to make error state determinations from an API call.

  Example:
  await waitForSaga(store);

  Look at pages/search.js for an example of this.

  Caveat:
  This will prevent any subsequent sagas from running; if you need to make more requests after waiting for this function, you can use:
  store.runSagaTask();
 */
export default async function waitForSaga(store) {
  store.dispatch(END);

  return store.sagaTask.toPromise();
}
