import PropTypes from 'prop-types';
import React, { useState } from 'react';
import ReactPlayer from 'react-player/lazy';
import { useDispatch } from 'react-redux';
import { Box, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import mediaApi from 'api/media';
import isempty from 'lodash.isempty';
import MasonryLayout from 'components/MasonryLayout';
import PlayButton from 'components/PlayButton';
import { WHITE } from 'styles/colors';
import isYoutubeLink from 'helpers/isYoutubeLink';
import { profileMediaVideoPlay } from 'state/modules/analytics';
import {
  DEFAULT_AUDIO_THUMBNAIL,
  AWS_S3_MEDIA_HOST,
  VIMEO_MEDIA_HOST,
  YOUTUBE_MEDIA_HOST,
} from 'constants/Profiles/videoAudio';

const getPlayerThumbnail = (media) => {
  if (media.isVideo) {
    // use thumbnailUrl provided (s3) otherwise use default thumbnail provided by video (yt,vimeo)
    return media.thumbnailUrl ? media.thumbnailUrl : true;
  }
  return DEFAULT_AUDIO_THUMBNAIL;
};

const getMediaHost = (url) => {
  let mediaHost = AWS_S3_MEDIA_HOST;
  if (url.includes('vimeo')) {
    mediaHost = VIMEO_MEDIA_HOST;
  } else if (isYoutubeLink(url)) {
    mediaHost = YOUTUBE_MEDIA_HOST;
  }
  return mediaHost;
};

export const VideoAudio = ({ media, profileId }) => {
  const dispatch = useDispatch();
  const [playingVideoId, setPlayingVideoId] = useState(null);
  const [playingVideoRef, setPlayingVideoRef] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleVideoPlay = async (m) => {
    dispatch(profileMediaVideoPlay(m.videoHost, m.id));

    await mediaApi.logView(profileId, m.id);
  };

  const handleVideoReady = (m, playerRef) => {
    if (m.id !== playingVideoId) {
      setPlayingVideoId(m.id);
      if (playingVideoRef) {
        playingVideoRef.showPreview();
      }
      setPlayingVideoRef(playerRef);
    }
  };

  const memberMedia = !isempty(media)
    ? media
        .sort((a, b) => a.sequence - b.sequence)
        .map((m) => ({
          id: m.id,
          url: m.url,
          sequence: m.sequence,
          thumbnailUrl: m.thumbnailUrl,
          title: m.title,
          isVideo: m.isVideo,
          mediaHost: getMediaHost(m.url),
        }))
    : [];
  const mediaHeight = isMobile ? 255 : 400;

  return (
    <MasonryLayout gap={30} columns={isMobile ? 1 : 2}>
      {memberMedia.map((m) => {
        const isPlaying = playingVideoId === m.id;
        return (
          <Box
            key={m.url}
            sx={{
              height: mediaHeight,
              overflow: 'hidden',
              borderRadius: '3px',
              ...(!m.isVideo
                ? {
                    backgroundSize: 'cover',
                    backgroundPosition: 'center center',
                    backgroundImage: `url(${DEFAULT_AUDIO_THUMBNAIL})`,
                  }
                : {}),
            }}
          >
            <ReactPlayer
              width="100%"
              height="100%"
              light={getPlayerThumbnail(m)}
              controls
              url={m.url}
              playing={isPlaying}
              onStart={() => handleVideoPlay(m)}
              onReady={(el) => handleVideoReady(m, el)}
              playIcon={<PlayButton />}
            />
            {m.title && !isPlaying && (
              <Box
                sx={{
                  padding: '0 12px',
                  bottom: 56,
                  height: 56,
                  position: 'relative',
                  background: 'rgba(0, 0, 0, 0.7)',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: WHITE,
                  textOverflow: 'ellipsis',
                }}
              >
                <Box
                  component="span"
                  sx={{
                    width: { xs: 300, md: 500 },
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'initial',
                    display: '-webkit-box',
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: 'vertical',
                    textAlign: 'center',
                  }}
                >
                  {m.title}
                </Box>
              </Box>
            )}
          </Box>
        );
      })}
    </MasonryLayout>
  );
};

VideoAudio.propTypes = {
  media: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      legacyId: PropTypes.number,
      url: PropTypes.string.isRequired,
      thumbnailUrl: PropTypes.string,
      isVideo: PropTypes.bool.isRequired,
      sequence: PropTypes.number,
      title: PropTypes.string,
    })
  ),
  isMobile: PropTypes.bool,
  profileId: PropTypes.number.isRequired,
};

VideoAudio.defaultProps = {
  media: [],
};

export default VideoAudio;
