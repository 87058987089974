import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Typography } from '@tkww/the-bash-frontend';

const StyledDetailContainer = styled(Box)({
  marginLeft: 0,
  display: 'flex',
  flexDirection: 'row-reverse',
  justifyContent: 'flex-end',
});

const StyledDetailSection = styled(Box)(({ theme }) => ({
  fontFamily: theme.fonts.regular,
  ...theme.fontSizes[18],
  fontWeight: 400,
  marginLeft: 10,
}));

const ServiceColumn = ({ category, displayExcluded, serviceAttributes, selectedAttributes }) => {
  const filteredAttributes = serviceAttributes.filter((s) => s.parentId === category.id);

  return (
    <>
      <Typography
        variant="h3"
        id={`category-${category.id}`}
        sx={(theme) => ({
          fontFamily: theme.fonts.semibold,
          fontSize: 20,
          marginTop: 0,
        })}
      >
        {category.title}
      </Typography>
      <dl key={category.title}>
        {filteredAttributes.map((sa) =>
          selectedAttributes.map((selected) =>
            sa.slug === selected ? (
              <StyledDetailContainer key={`${sa.title}-yes`}>
                <StyledDetailSection component="dt" key={sa.title} id={sa.slug}>
                  {sa.title}
                </StyledDetailSection>
                <Box
                  component="dd"
                  data-testid="yesMark"
                  aria-labelledby={sa.slug}
                  sx={(theme) => ({
                    marginInlineStart: 0,
                    '&:before': {
                      width: 10,
                      content: '"\\2713"',
                      color: theme.palette.primary.main,
                      fontWeight: 600,
                      paddingRight: 0.5,
                    },
                  })}
                />
              </StyledDetailContainer>
            ) : null
          )
        )}
        {displayExcluded &&
          filteredAttributes
            .filter((s) => !selectedAttributes.includes(s.slug))
            .map((sa) => (
              <StyledDetailContainer key={`${sa.title}-no`}>
                <StyledDetailSection
                  component="dt"
                  key={sa.title}
                  id={sa.slug}
                  sx={{
                    opacity: 0.7,
                  }}
                >
                  {sa.title}
                </StyledDetailSection>
                <Box
                  component="dd"
                  data-testid="noMark"
                  sx={(theme) => ({
                    marginInlineStart: 0,
                    '&:before': {
                      opacity: 0.7,
                      width: 10,
                      content: '"\\2715"',
                      fontWeight: 400,
                      color: theme.palette.medium,
                      paddingRight: '3px',
                    },
                  })}
                  aria-labelledby={sa.slug}
                />
              </StyledDetailContainer>
            ))}
      </dl>
    </>
  );
};

ServiceColumn.propTypes = {
  category: PropTypes.shape({}).isRequired,
  serviceAttributes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selectedAttributes: PropTypes.arrayOf(PropTypes.string).isRequired,
  displayExcluded: PropTypes.bool,
};

ServiceColumn.defaultProps = {
  displayExcluded: false,
};

export default ServiceColumn;
