import { getNextRedirect } from 'helpers/redirects';
import { checkForProfileRedirect } from 'helpers/profiles/redirects';

const checkForVendorReviewsRedirect = (profile, queryStringParameters) => {
  if (!profile) {
    return null;
  }

  if (profile.numberOfReviews === 0) {
    return getNextRedirect(profile.profileUrl, true);
  }
  return checkForProfileRedirect(profile, 'feedback', queryStringParameters);
};

export default checkForVendorReviewsRedirect;
