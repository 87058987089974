import PropTypes from 'prop-types';
import React from 'react';
import { Box } from '@mui/material';

export const MediaTabLabel = ({ classes, text, count }) => (
  <>
    <span data-testid="media-tab-label-text">{text}</span>
    <Box
      component="span"
      sx={(theme) => ({ color: theme.palette.primary.main })}
      data-testid="media-tab-label-count"
    >
      {' '}
      ({count})
    </Box>
  </>
);

MediaTabLabel.propTypes = {
  text: PropTypes.string.isRequired,
  count: PropTypes.number,
};

MediaTabLabel.defaultProps = {
  count: 0,
};

export default MediaTabLabel;
