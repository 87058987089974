export const REVIEWS_SORT_TYPES = {
  RECOMMENDED: 1,
  RATINGS: 2,
  DATE: 3,
};

export const SORT_LABEL_BY_VALUE = {
  [REVIEWS_SORT_TYPES.RECOMMENDED]: 'Recommended',
  [-REVIEWS_SORT_TYPES.RATINGS]: 'Ratings: High to Low',
  [REVIEWS_SORT_TYPES.RATINGS]: 'Ratings: Low to High',
  [-REVIEWS_SORT_TYPES.DATE]: 'Date: Newest to Oldest',
  [REVIEWS_SORT_TYPES.DATE]: 'Date: Oldest to Newest',
};

export const SORT_BY_OPTIONS = [
  { label: 'Recommended', value: REVIEWS_SORT_TYPES.RECOMMENDED },
  { label: 'Ratings: High to Low', value: -REVIEWS_SORT_TYPES.RATINGS },
  { label: 'Ratings: Low to High', value: REVIEWS_SORT_TYPES.RATINGS },
  { label: 'Date: Newest to Oldest', value: -REVIEWS_SORT_TYPES.DATE },
  { label: 'Date: Oldest to Newest', value: REVIEWS_SORT_TYPES.DATE },
];
