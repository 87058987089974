import { useCallback, useEffect, useState } from 'react';

/**
 * Use this hook to keep track of node dimensions for a given component.
 *
 * @param ref React ref for component/node to be tracked.
 * @returns {{}}
 */
const useNodeDimensions = ref => {
  const [dimensions, setDimensions] = useState({});

  const handleScroll = useCallback(
    () => {
      if (!ref || !ref.current) return;
      setDimensions(ref.current.getBoundingClientRect());
    },
    [ref]
  );

  useEffect(
    () => {
      if (ref) {
        window.addEventListener('scroll', handleScroll);
      }

      return () => window.removeEventListener('scroll', handleScroll);
    },
    [ref, handleScroll]
  );

  return dimensions;
};

export default useNodeDimensions;
