import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import ExpandableSection from 'components/ExpandableSection';
import { Section, sectionPropTypes, sectionDefaultProps } from 'views/Profile/Sections';
import Services from 'views/Profile/VenueProfilePage/VenueServices/Services';
import { viewMoreCtaClick } from 'state/modules/analytics';

export const ServiceAttributes = ({
  onClickMore,
  parentCategories,
  profile,
  sectionName,
  selectedAttributes,
  serviceAttributes,
  title,
  subTitle,
  ...rest
}) => {
  const dispatch = useDispatch();
  if (!(profile.serviceAttributes.length > 0)) return null;

  const handleOnClickMore = (clickArea) =>
    onClickMore && onClickMore(title) && dispatch(viewMoreCtaClick(clickArea));

  return (
    <Section
      {...rest}
      sectionName={sectionName}
      title={title}
      subTitle={subTitle}
      right={
        <ExpandableSection fadeTruncatedText onClickMore={handleOnClickMore} id={sectionName}>
          <Services
            parentCategories={parentCategories}
            serviceAttributes={serviceAttributes}
            selectedAttributes={selectedAttributes}
          />
        </ExpandableSection>
      }
    />
  );
};

ServiceAttributes.propTypes = {
  ...sectionPropTypes,
  onClickMore: PropTypes.func,
  profile: PropTypes.shape({}).isRequired,
  sectionName: PropTypes.string.isRequired,
};

ServiceAttributes.defaultProps = {
  ...sectionDefaultProps,
  onClickMore: () => {},
};

export default ServiceAttributes;
