import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Box } from '@mui/material';

import { Typography } from '@tkww/the-bash-frontend';

import { premiumProfileTileClick } from 'state/modules/analytics';

import PremiumProfileTile from './PremiumProfileTile';
import { dropShadow } from 'styles/classes';

const PremiumProfiles = ({ title, profiles, lazyLoadThumbnails, successPage }) => {
  const dispatch = useDispatch();

  const trackTileClick = (profile) => {
    dispatch(premiumProfileTileClick(profile, successPage));
  };

  return (
    <>
      <Typography variant="h2" mt={0}>
        {title}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: 3,
        }}
      >
        {profiles.map((profile) => (
          <PremiumProfileTile
            key={profile.memberID}
            profile={profile}
            lazyLoadThumbnail={lazyLoadThumbnails}
            onClick={() => trackTileClick(profile)}
          />
        ))}
      </Box>
    </>
  );
};

PremiumProfiles.propTypes = {
  title: PropTypes.string.isRequired,
  profiles: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  lazyLoadThumbnails: PropTypes.bool.isRequired,
  successPage: PropTypes.bool,
};

PremiumProfiles.defaultProps = {
  lazyLoadThumbnails: false,
  successPage: false,
};

export default PremiumProfiles;
