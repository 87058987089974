const profileReviewsPageViewProps = (profile) => {
  if (!profile) return null;

  return {
    memberID: profile.id || null,
    primaryCategory: profile.primaryServiceType.personForm || null,
    primaryCity: profile.city.name || null,
    primaryState: profile.city.stateOrProvince.name || null,
    profileName: profile.profileName || null,
    numReviews: profile.numberOfReviews || null,
    numBookings: profile.verifiedBookingsCount || null,
    avgStarRating: profile.rating || null,
    firstAiDescription: profile.firstAIAssistedDescriptionDate || null,
    mostRecentAiDescription: profile.lastAIAssistedDescriptionDate || null,
  };
};

export default profileReviewsPageViewProps;
