import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { Button } from '@tkww/the-bash-frontend';
import sxPropType from 'propTypes/sx';

export const LoadMore = ({ isLoading, onClick, testId, hideLoadMore, sx }) => {
  const dispatch = useDispatch();

  if (hideLoadMore) return null;

  return (
    <Button
      data-testid={testId}
      loading={isLoading}
      onClick={() => dispatch(onClick())}
      sx={{
        width: 134,
        height: 46,
        fontSize: 16,
        lineHeight: '18px',
        textTransform: 'uppercase',
        ...sx,
      }}
      type="secondary"
    >
      Load More
    </Button>
  );
};

LoadMore.propTypes = {
  isLoading: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  hideLoadMore: PropTypes.bool,
  testId: PropTypes.string,
  sx: sxPropType,
};

LoadMore.defaultProps = {
  isLoading: false,
  hideLoadMore: false,
  testId: 'load-more',
  sx: {},
};

export default LoadMore;
