import React from 'react';
import { useDispatch } from 'react-redux';
import isEmpty from 'lodash.isempty';
import { ServiceAttributesSection } from 'views/Profile/Sections';
import { viewMoreCtaClick } from 'state/modules/analytics';
import {
  CATERING_SECTION_ID,
  CATERING_SECTION_TITLE,
  CATERING_SECTION_SUBTITLE,
  PROVIDED_SERVICES_SECTION_ID,
  PROVIDED_SERVICES_SECTION_VENUE_TITLE,
  PROVIDED_SERVICES_SECTION_VENUE_SUBTITLE,
} from 'constants/Profiles';

const VENUE_SERVICES_PARENT_CATEGORIES = [
  { title: 'Amenities', id: 71 },
  { title: 'Features', id: 70 },
  { title: 'Staff & Services', id: 95 },
  { title: 'Vibes', id: 109, hideWhenEmpty: true },
];

const CATERING_PARENT_CATEGORIES = [
  { title: 'Courses', id: 17 },
  { title: 'Serving Options', id: 60 },
  { title: 'Dietary Accommodations', id: 22 },
  { title: 'Regional Cuisine', id: 55, hideWhenEmpty: true },
];

const VenueServices = ({ profile, serviceAttributes }) => {
  const dispatch = useDispatch();

  const selectedAttributes = !isEmpty(profile.serviceAttributes) ? profile.serviceAttributes : [];

  const filteredAttributes = (parentCategories) =>
    serviceAttributes
      .filter((sa) => parentCategories.some((pc) => pc.id === sa.parentId))
      .map((s) => s.slug);

  const hasContent = (attribute) => selectedAttributes.includes(attribute);
  const trackViewMoreClick = (clickArea) => dispatch(viewMoreCtaClick(clickArea));

  return (
    <>
      {profile.serviceAttributes.length > 0 && (
        <ServiceAttributesSection
          onClickMore={trackViewMoreClick}
          profile={profile}
          sectionName={PROVIDED_SERVICES_SECTION_ID}
          title={PROVIDED_SERVICES_SECTION_VENUE_TITLE}
          subTitle={PROVIDED_SERVICES_SECTION_VENUE_SUBTITLE}
          parentCategories={VENUE_SERVICES_PARENT_CATEGORIES}
          serviceAttributes={serviceAttributes}
          selectedAttributes={selectedAttributes}
        />
      )}
      {filteredAttributes(CATERING_PARENT_CATEGORIES).some(hasContent) && (
        <ServiceAttributesSection
          onClickMore={trackViewMoreClick}
          profile={profile}
          sectionName={CATERING_SECTION_ID}
          title={CATERING_SECTION_TITLE}
          subTitle={CATERING_SECTION_SUBTITLE}
          parentCategories={CATERING_PARENT_CATEGORIES}
          serviceAttributes={serviceAttributes}
          selectedAttributes={selectedAttributes}
        />
      )}
    </>
  );
};

export default VenueServices;
