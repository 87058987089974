import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import ExpandableSection from 'components/ExpandableSection';
import { SONGS_SECTION_ID } from 'constants/Profiles';
import { Section, sectionPropTypes, sectionDefaultProps } from 'views/Profile/Sections';

export const SongList = ({ songList, ...rest }) => {
  if (!songList || songList.length === 0) {
    return null;
  }

  const getSongDisplay = (song) => {
    const title = song.title ? song.title.trim() : '';
    const artist = song.artist ? song.artist.trim() : '';

    if (title && artist) {
      return (
        <>
          <Box component="span" sx={(theme) => ({ fontFamily: theme.fonts.bold })}>
            {title}
          </Box>{' '}
          | {artist}
        </>
      );
    }
    if (song.title) {
      return title;
    }
    return artist;
  };

  return (
    <Section
      {...rest}
      sectionName={SONGS_SECTION_ID}
      right={
        <ExpandableSection fadeTruncatedText>
          <Box
            component="ul"
            sx={(theme) => ({
              marginTop: '0px',
              [theme.breakpoints.up('md')]: {
                columnCount: 2,
                columnGap: '30px',
              },
            })}
          >
            {songList.map((song, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Box component="li" margin="0 0 8px 0" key={index}>
                {getSongDisplay(song)}
              </Box>
            ))}
          </Box>
        </ExpandableSection>
      }
    />
  );
};

SongList.propTypes = {
  ...sectionPropTypes,
  songList: PropTypes.arrayOf(
    PropTypes.shape({
      artist: PropTypes.string,
      title: PropTypes.string,
      sortOrder: PropTypes.number,
    })
  ),
};

SongList.defaultProps = {
  ...sectionDefaultProps,
  songList: [],
};

export default SongList;
