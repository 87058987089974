import React from 'react';
import PropTypes from 'prop-types';
import { Section } from 'views/Profile/Sections';
import { RELATED_PROFILES_SECTION_ID } from 'constants/Profiles';
import RelatedProfileCards from './RelatedProfileCards';

export const RelatedProfile = ({ profile, ...rest }) => {
  if (!profile.relatedProfiles.length) {
    return null;
  }

  return (
    <Section
      {...rest}
      sectionName={RELATED_PROFILES_SECTION_ID}
      right={<RelatedProfileCards profile={profile} />}
    />
  );
};

RelatedProfile.propTypes = {
  profile: PropTypes.shape({
    stars: PropTypes.number,
    thumbnailUrl: PropTypes.string.isRequired,
    profileName: PropTypes.string.isRequired,
    numberOfReviews: PropTypes.number,
    primaryServiceType: PropTypes.shape({
      personForm: PropTypes.string,
    }),
    cityState: PropTypes.string,
    canonicalProfileDirectory: PropTypes.string,
  }).isRequired,
};

export default RelatedProfile;
