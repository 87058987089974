import React from 'react';
import PropTypes from 'prop-types';
import { JumpToLocation } from '@tkww/the-bash-frontend';
import { useDispatch } from 'react-redux';
import { Box } from '@mui/material';

import { jumpToCaretCtaClick } from 'state/modules/analytics/profile';
import getSxAttributes from 'helpers/getSxAttributes';
import sxPropType from 'propTypes/sx';

export const Details = ({ details, sx }) => {
  const dispatch = useDispatch();

  return (
    <Box
      component="ul"
      sx={(theme) => ({
        listStyle: 'none',
        paddingInlineStart: 0,
        marginTop: 0,
        '& .icon': { width: 22, height: 22, marginRight: 1.5, display: 'inline-block' },
        '& .jumpToLocation': {
          marginLeft: 1,
        },
        ...getSxAttributes(theme, sx),
      })}
      aria-label="details"
    >
      {details.map((detail, i) => {
        const Icon = detail.icon;
        const Detail = detail.detail;
        return (
          // eslint-disable-next-line react/no-array-index-key
          <Box
            component="li"
            key={i}
            sx={(theme) => ({
              display: 'flex',
              alignItems: 'center',
              margin: '0 0 10px 0',
              color: theme.palette.text.primary,
              fontFamily: theme.fonts.regular,
              textTransform: 'none',
            })}
          >
            <Icon className="icon" />
            <span>
              <Detail />
            </span>
            {detail.jumpTo && (
              <JumpToLocation
                className="jumpToLocation"
                target={`#${detail.jumpTo}`}
                onClick={() => dispatch(jumpToCaretCtaClick(detail.title))}
                data-testid="detail-caret-icon"
              />
            )}
          </Box>
        );
      })}
    </Box>
  );
};

Details.propTypes = {
  details: PropTypes.arrayOf(
    PropTypes.exact({
      icon: PropTypes.elementType.isRequired,
      detail: PropTypes.elementType.isRequired,
      title: PropTypes.string,
      jumpTo: PropTypes.string,
    })
  ).isRequired,
  sx: sxPropType,
};

Details.defaultProps = {
  sx: {},
};

export default Details;
