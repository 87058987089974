import PropTypes from 'prop-types';
import React from 'react';

import Icon from 'components/Icon';
import src from 'public/images-next/dollar_circle_small.svg';

const DollarCircle = ({ className }) => <Icon src={src} className={className} />;

DollarCircle.propTypes = {
  className: PropTypes.string,
};

DollarCircle.defaultProps = {
  className: '',
};

export default DollarCircle;
