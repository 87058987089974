import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import sxPropType from 'propTypes/sx';

const MasonryLayout = ({ columns, gap, children, sx }) => {
  const columnWrapper = {};
  const result = [];

  // create columns
  for (let i = 0; i < children.length; i += 1) {
    const columnIndex = i % columns;
    if (!columnWrapper[`column${columnIndex}`]) {
      columnWrapper[`column${columnIndex}`] = [];
    }
    columnWrapper[`column${columnIndex}`].push(
      <div className="item" key={i} style={{ marginBottom: `${gap}px` }}>
        {children.filter((item) => item != null)[i]}
      </div>
    );
  }

  for (let i = 0; i < columns; i += 1) {
    result.push(
      <div
        data-testid="column"
        key={i}
        style={{
          marginLeft: `${i > 0 ? gap : 0}px`,
          flex: 1,
        }}
      >
        {columnWrapper[`column${i}`]}
      </div>
    );
  }

  return (
    <Box display="flex" sx={sx}>
      {result}
    </Box>
  );
};

MasonryLayout.propTypes = {
  columns: PropTypes.number,
  gap: PropTypes.number,
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
  sx: sxPropType,
};

MasonryLayout.defaultProps = {
  columns: 3,
  gap: 20,
  sx: {},
};

export default MasonryLayout;
