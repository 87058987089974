import React from 'react';
import PropTypes from 'prop-types';
import { JsonLD } from '@tkww/the-bash-frontend';

import { buildReviewSchema } from 'helpers/jsonLd';

const ReviewSnippetJsonLD = ({ review, profile }) => (
  <JsonLD data={buildReviewSchema(review, profile)} />
);

ReviewSnippetJsonLD.propTypes = {
  review: PropTypes.shape({
    gigRequestState: PropTypes.string.isRequired,
    gigRequestCity: PropTypes.string.isRequired,
    satisfactionScore: PropTypes.number.isRequired,
    clientFirstname: PropTypes.string.isRequired,
    clientLastNameAbbreviation: PropTypes.string.isRequired,
    text: PropTypes.string,
    dateAdded: PropTypes.string,
  }).isRequired,
  profile: PropTypes.shape({
    profileName: PropTypes.string.isRequired,
    thumbnailUrl: PropTypes.string.isRequired,
  }).isRequired,
};

export default ReviewSnippetJsonLD;
