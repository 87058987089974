import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';

import ServiceColumn from './ServiceColumn';

const Services = ({ parentCategories, serviceAttributes, selectedAttributes }) => {
  const filteredAttributes = (parent) =>
    serviceAttributes.filter((sa) => sa.parentId === parent.id).map((sa) => sa.slug);

  const hasContent = (attribute) => selectedAttributes.includes(attribute);

  return (
    <>
      <Grid container xs={12}>
        {parentCategories.map((category) => {
          const show = !category.hideWhenEmpty || filteredAttributes(category).some(hasContent);
          return (
            show && (
              <Grid
                item
                xs={6}
                md={6}
                component="section"
                aria-labelledby={`category-${category.id}`}
                key={`${category.title}`}
              >
                <ServiceColumn
                  category={category}
                  serviceAttributes={serviceAttributes}
                  selectedAttributes={selectedAttributes}
                  displayExcluded={!category.hideWhenEmpty}
                />
              </Grid>
            )
          );
        })}
      </Grid>
    </>
  );
};

Services.propTypes = {
  parentCategories: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  serviceAttributes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selectedAttributes: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default Services;
