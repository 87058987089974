import React from 'react';
import PropTypes from 'prop-types';
import { Box, FormControl, Select, Typography } from '@mui/material';

import { WHITE } from 'styles/colors';

const Dropdown = ({ value, label, handleChange, options, sx }) => (
  <Box component="span" display="flex" alignItems="center" sx={sx}>
    <Typography fontSize={16}>{label}:</Typography>
    <FormControl
      variant="outlined"
      sx={(theme) => ({
        backgroundColor: theme.palette.background.default,
        marginLeft: theme.spacing(1),
        '& fieldset': {
          borderColor: theme.palette.grey[500],
        },
      })}
    >
      <Select
        native
        sx={{
          height: 32,
          paddingTop: 0,
          paddingBottom: 0,
          fontSize: 16,
          '&:focus': {
            backgroundColor: WHITE,
          },
        }}
        value={value}
        onChange={handleChange}
      >
        {options.map((option) => (
          <option value={option.value} key={option.value}>
            {option.label}
          </option>
        ))}
      </Select>
    </FormControl>
  </Box>
);

Dropdown.propTypes = {
  value: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  options: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  }).isRequired,
  sx: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({})]),
};

export default Dropdown;
