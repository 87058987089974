import React from 'react';
import PropTypes from 'prop-types';
import { Box, Container } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Link, Typography } from '@tkww/the-bash-frontend';

const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.palette.primary.main,
}));

export const SimilarVenues = ({ profile, addTopDivider, ...rest }) => {
  const venueMarketFilename = profile.venue.market.fileName;

  return (
    <Box sx={(theme) => ({ padding: '0', backgroundColor: theme.palette.background.default })}>
      <Container maxWidth="xl">
        <Box
          sx={(theme) => ({
            borderTop: addTopDivider ? `1px solid ${theme.palette.grey['400']}` : 'none',
            textTransform: 'capitalize',
            overflowWrap: 'break-word',
            marginBottom: 7,
          })}
          className="test-similar-venues"
          role="complementary"
          aria-labelledby="similar-venues"
        >
          <Typography
            variant="h3"
            className="test-similar-venues-title"
            sx={(theme) => ({
              fontWeight: theme.fontWeights.regular,
              marginBottom: 1.5,
            })}
            id="similar-venues"
            data-testid="similar-venues"
          >
            Explore Similar Venues
          </Typography>
          <Typography variant="body1" data-testid="similar-venues-link">
            See All{' '}
            <StyledLink
              path={`/search/venue-${venueMarketFilename}?venueType=${profile.primaryServiceType.fileName}`}
              nextPath="/search/[slug]"
              data-testid="sv-link"
            >
              {profile.primaryServiceType.pluralForm}
            </StyledLink>
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

SimilarVenues.propTypes = {
  profile: PropTypes.shape({}).isRequired,
  addTopDivider: PropTypes.bool,
};

SimilarVenues.defaultProps = {
  addTopDivider: true,
};

export default SimilarVenues;
