import PropTypes from 'prop-types';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import isEmpty from 'lodash.isempty';
import { Box, useTheme, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

import { Button } from '@tkww/the-bash-frontend';
import { PHOTO_TYPES } from 'constants/photo';
import { profileShape } from 'propTypes/profile';
import buildSmartCroppedUrl from 'helpers/media/buildSmartCroppedUrl';
import { selectIsMobile } from 'state/modules/viewport';
import { profileHeroImageClick, profileMediaButtonCtaClick } from 'state/modules/analytics';
import FALLBACK_IMAGE, { EMPTY_IMAGE } from 'constants/fallbackImage';
import getProfileHeroImages from 'helpers/profiles/getProfileHeroImages';

const backgroundImageStyles = (imageUrl, isMobile) => {
  let imageStyles = `url(${
    imageUrl || (isMobile ? FALLBACK_IMAGE.mobile : FALLBACK_IMAGE.desktop)
  })`;

  if (imageUrl) {
    imageStyles = `linear-gradient(rgba(0,0,0,0.6),rgba(0,0,0,0.6)), ${imageStyles}`;
  }

  return imageStyles;
};

const StyledGalleryImage = styled(Box, { component: 'picture' })({
  height: '48.75%',
  width: '100%',
  borderRadius: '4px',
  objectFit: 'cover',
  overflow: 'hidden',
  marginBottom: '10px',
  display: 'block',
});

const getPhotosToDisplay = (photos) => {
  const photosToDisplay = photos
    .filter((p) => [PHOTO_TYPES.PROFILE, PHOTO_TYPES.GALLERY].indexOf(p.type) > -1)
    .sort((a, b) => a.sequence - b.sequence)
    .sort((a) => (a.type === PHOTO_TYPES.PROFILE ? -1 : 1))
    .slice(0, 5);

  if (photosToDisplay.length <= 2) return photosToDisplay.slice(0, 1);
  if (photosToDisplay.length <= 4) return photosToDisplay.slice(0, 3);
  return photosToDisplay;
};

const getMainProfileImageNumCol = (numPhotos) => {
  if (numPhotos > 4) {
    return 6;
  }
  if (numPhotos > 2) {
    return 9;
  }
  return 12;
};

export const Hero = ({ profile, onMediaButtonClick }) => {
  const photosToDisplay = getPhotosToDisplay(profile.photos);
  const galleryPhotos = profile.photos.filter((p) => p.type === PHOTO_TYPES.GALLERY).length;
  const isMobile = useSelector(selectIsMobile);
  const dispatch = useDispatch();
  const theme = useTheme();
  const vendorPhotoAltText = `${profile.profileName} - ${profile.primaryServiceType.personForm} - ${profile.city.displayName}`;
  const showMediaButton = galleryPhotos > 5 || !isEmpty(profile.media);

  const mainProfileImageClick = () => {
    if (isMobile) {
      dispatch(profileHeroImageClick(profile));
      onMediaButtonClick();
    }
  };
  const mediaButtonClick = (isPhotosButton) => {
    dispatch(profileMediaButtonCtaClick(profile, isPhotosButton));
    onMediaButtonClick();
  };

  const desktopPhotoUrls = [];
  const mobilePhotoUrls = [];

  photosToDisplay.forEach((p) => {
    if (p.type === PHOTO_TYPES.GALLERY) {
      desktopPhotoUrls.push(buildSmartCroppedUrl(p.url, 275, 205));
      mobilePhotoUrls.push(EMPTY_IMAGE);
    } else {
      const profileHeroImages = getProfileHeroImages(p.url);
      desktopPhotoUrls.push(profileHeroImages.desktop);
      mobilePhotoUrls.push(profileHeroImages.mobile);
    }
  });

  const mainDesktopPhoto = desktopPhotoUrls[0];
  const mainMobilePhoto = mobilePhotoUrls[0];

  const mobileGalleryImageMediaQuery = `(max-width: ${theme.breakpoints.values.sm - 0.1}px)`;
  const desktopGalleryImageMediaQuery = `(min-width: ${theme.breakpoints.values.sm}px)`;

  return (
    <Grid
      container
      spacing={2}
      sx={{
        WebkitBoxPack: 'center',
        WebkitBoxAlign: 'center',
        display: 'flex',
        position: 'relative',
        overflow: 'hidden',
        height: { xs: 264, md: 360, lg: 420 },
        '& .column': {
          position: 'relative',
          height: { xs: 264, md: '100%' },
          borderRadius: 1,
          overflow: 'hidden',
        },
      }}
    >
      <Grid
        item
        xs={12}
        md={desktopPhotoUrls.length < 3 ? 12 : 9}
        lg={getMainProfileImageNumCol(desktopPhotoUrls.length)}
      >
        <Box
          className="column"
          padding={0}
          onClick={mainProfileImageClick}
          onKeyPress={mediaButtonClick}
          tabIndex="0"
          role="button"
        >
          <Box
            data-testid="background-image"
            sx={(theme) => ({
              backgroundSize: 'cover',
              height: '100%',
              width: '100%',
              position: 'absolute',
              filter: mainDesktopPhoto ? 'blur(3px)' : null,
              [theme.breakpoints.up('md')]: {
                backgroundImage: backgroundImageStyles(mainMobilePhoto, true),
              },
              [theme.breakpoints.up('sm')]: {
                backgroundImage: backgroundImageStyles(mainDesktopPhoto, false),
              },
            })}
          />
          {mainDesktopPhoto && (
            <picture>
              <source srcSet={mainMobilePhoto} media={mobileGalleryImageMediaQuery} />
              <source srcSet={mainDesktopPhoto} media={desktopGalleryImageMediaQuery} />
              <Box
                component="img"
                sx={{
                  position: 'absolute',
                  textAlign: 'center',
                  height: '100%',
                  maxWidth: '100%',
                  left: '50%',
                  top: '50%',
                  transform: 'translateX(-50%) translateY(-50%);',
                  objectFit: 'contain',
                }}
                src={mainMobilePhoto}
                alt={`${vendorPhotoAltText} - Hero Main`}
                data-testid="profile-image"
              />
            </picture>
          )}
        </Box>
      </Grid>
      {photosToDisplay.length >= 3 && (
        <Grid item xs={false} md={3} data-testid="col-1" className="column">
          <StyledGalleryImage>
            <source srcSet={mobilePhotoUrls[1]} media={mobileGalleryImageMediaQuery} />
            <source srcSet={desktopPhotoUrls[1]} media={desktopGalleryImageMediaQuery} />
            <img src={desktopPhotoUrls[1]} alt={`${vendorPhotoAltText} - Hero Gallery 1`} />
          </StyledGalleryImage>
          <StyledGalleryImage>
            <source srcSet={mobilePhotoUrls[2]} media={mobileGalleryImageMediaQuery} />
            <source srcSet={desktopPhotoUrls[2]} media={desktopGalleryImageMediaQuery} />
            <img src={desktopPhotoUrls[2]} alt={`${vendorPhotoAltText} - Hero Gallery 2`} />
          </StyledGalleryImage>
        </Grid>
      )}
      {photosToDisplay.length >= 5 && (
        <Grid item xs={false} md={false} lg={3} data-testid="col-2" className="column">
          <StyledGalleryImage>
            <source srcSet={mobilePhotoUrls[3]} media={mobileGalleryImageMediaQuery} />
            <source srcSet={desktopPhotoUrls[3]} media={desktopGalleryImageMediaQuery} />
            <img src={desktopPhotoUrls[3]} alt={`${vendorPhotoAltText} - Hero Gallery 3`} />
          </StyledGalleryImage>
          <StyledGalleryImage>
            <source srcSet={mobilePhotoUrls[4]} media={mobileGalleryImageMediaQuery} />
            <source srcSet={desktopPhotoUrls[4]} media={desktopGalleryImageMediaQuery} />
            <img src={desktopPhotoUrls[4]} alt={`${vendorPhotoAltText} - Hero Gallery 4`} />
          </StyledGalleryImage>
        </Grid>
      )}
      {showMediaButton && (
        <Box
          sx={(theme) => ({
            display: { xs: 'none', md: 'block' },
            position: 'absolute',
            bottom: 8,
            right: 18,
            backgroundColor: theme.palette.grey[200],
          })}
        >
          <Button type="secondary" onClick={() => mediaButtonClick(galleryPhotos > 5)}>
            See All {galleryPhotos > 5 ? 'Photos' : 'Media'}
          </Button>
        </Box>
      )}
    </Grid>
  );
};

Hero.propTypes = {
  profile: profileShape.isRequired,
  onMediaButtonClick: PropTypes.func,
};

Hero.defaultProps = {
  onMediaButtonClick: () => {},
};

export default React.memo(Hero);
