import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/material';
import { Button, CaretIcon } from '@tkww/the-bash-frontend';

import jump from 'helpers/jump';
import { selectIsMobile } from 'state/modules/viewport';
import { viewMoreCtaClick } from 'state/modules/analytics';

const ExpandableSection = ({
  className,
  onClickMore,
  fadeTruncatedText,
  children,
  id,
  clickArea,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const isMobile = useSelector(selectIsMobile);
  const dispatch = useDispatch();
  const [needsTruncation, setNeedsTruncation] = useState(false);
  const shouldTruncateContent = needsTruncation && !isExpanded;
  const ref = useRef();

  function clickEvent() {
    onClickMore();
    if (clickArea) {
      dispatch(viewMoreCtaClick(clickArea, isExpanded));
    }

    setIsExpanded(!isExpanded);
    if (isExpanded && id !== '') jump(`#${id}`, isMobile ? -60 : -90, 0);
  }

  const ToggleButton = () => (
    <Button
      sx={{
        textTransform: 'uppercase',
        textAlign: 'left',
        margin: 0,
        paddingTop: '12px',
        paddingLeft: 0,
        paddingBottom: 0,
        minWidth: isExpanded ? '90px' : null,
      }}
      type="tertiary"
      onClick={clickEvent}
    >
      {isExpanded ? 'VIEW LESS' : 'VIEW ALL'}{' '}
      <CaretIcon direction={isExpanded ? 'top' : 'bottom'} />
    </Button>
  );

  useEffect(() => {
    const { current } = ref;
    const maxHeight = isMobile ? 250 : 200;
    const shouldTruncate = current.scrollHeight > maxHeight;

    setNeedsTruncation(shouldTruncate);
  }, [isMobile]);

  return (
    <span className={className} data-testid="expandable-section">
      <Box
        ref={ref}
        sx={(theme) => ({
          whiteSpace: 'pre-line',
          textTransform: 'none',
          color: theme.palette.text.primary,
          ...(shouldTruncateContent
            ? {
                display: '-webkit-box',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                WebkitBoxOrient: 'vertical',
                maxHeight: '250px' /* Fallback for non-webkit */,
                WebkitLineClamp: 18,
                [theme.breakpoints.up('md')]: {
                  maxHeight: '200px',
                  WebkitLineClamp: 9,
                },
              }
            : {}),
        })}
      >
        {children}
      </Box>
      {needsTruncation && (
        <>
          <Box
            sx={(theme) => ({
              ...(!isExpanded && needsTruncation && fadeTruncatedText
                ? {
                    background: `linear-gradient(180deg, rgba(248, 248, 248, 0.263522) 64%, ${theme.palette.background.default} 100%)`,
                    height: 64,
                    marginTop: -8,
                    position: 'relative',
                  }
                : {}),
            })}
          />
          <ToggleButton />
        </>
      )}
    </span>
  );
};

ExpandableSection.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  onClickMore: PropTypes.func,
  fadeTruncatedText: PropTypes.bool,
  id: PropTypes.string,
  clickArea: PropTypes.string,
};

ExpandableSection.defaultProps = {
  className: '',
  onClickMore: () => {},
  fadeTruncatedText: false,
  id: '',
  clickArea: '',
};

export default ExpandableSection;
