import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Box, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Typography, Link } from '@tkww/the-bash-frontend';

import DollarCircleSmall from 'components/Icons/DollarCircleSmall';
import GuestsAltSmall from 'components/Icons/GuestsAltSmall';
import getResizeImageUrl from 'helpers/media/getResizeImageUrl';
import { relatedSpaceTileClick } from 'state/modules/analytics';
import FALLBACK_IMAGE from 'constants/fallbackImage';
import LazyLoad from 'react-lazyload';

const StyledLink = styled(Link)({
  display: 'flex',
  '&:hover': {
    textDecoration: 'none',
  },
});

const RelatedSpaces = ({ relatedSpaces }) => {
  const dispatch = useDispatch();
  const trackTileClick = (clickTarget, relatedSpace) =>
    dispatch(relatedSpaceTileClick(clickTarget, relatedSpace));

  return (
    <Grid container data-testid="related-spaces" xs={12}>
      {relatedSpaces.map((r) => (
        <Grid key={r.id} item md={12} lg={6}>
          <StyledLink
            path={`/profiles/${r.slug}`}
            onClick={() => trackTileClick('Profile Tile Click', r)}
            nextPath="/[dir1]/[dir2]"
          >
            <Box
              sx={{
                maxHeight: 95,
                minWidth: 95,
                maxWidth: 95,
                borderRadius: '3px',
                marginRight: '10px',
                overflow: 'hidden',
              }}
            >
              <LazyLoad>
                <img
                  src={
                    r.thumbnailUrl
                      ? getResizeImageUrl(r.thumbnailUrl, 95, 95, true)
                      : FALLBACK_IMAGE.mobile
                  }
                  alt={r.profileName}
                  onError={(e) => {
                    /* eslint-disable-next-line no-param-reassign */
                    e.target.src = FALLBACK_IMAGE.mobile;
                  }}
                />
              </LazyLoad>
            </Box>
            <Box>
              <Typography
                variant="inherit"
                component="p"
                sx={(theme) => ({
                  fontFamily: theme.fonts.regular,
                  fontWeight: 'bold',
                  maxHeight: 43,
                  overflow: 'hidden',
                  fontSize: 18,
                  lineHeight: 1.22,
                  marginBottom: 1,
                  color: theme.palette.text.primary,
                })}
              >
                {r.profileName}
              </Typography>
              <Typography
                variant="inherit"
                component="p"
                sx={(theme) => ({
                  color: theme.palette.text.primary,
                  fontFamily: theme.fonts.semibold,
                  fontSize: 11,
                  fontWeight: 700,
                  letterSpacing: 1,
                  lineHeight: 1.5,
                  marginBottom: 1,
                  textTransform: 'uppercase',
                })}
              >
                {r.primaryServiceType.name} in {r.cityState}
              </Typography>
              <Box
                sx={(theme) => ({
                  display: 'flex',
                  flexWrap: 'wrap',
                  '& p': {
                    lineHeight: 1.5,
                    fontWeight: 600,
                    fontSize: 11,
                    letterSpacing: '0.66px',
                    color: theme.palette.text.primary,
                    fontFamily: theme.fonts.regular,
                    textTransform: 'uppercase',
                    marginBottom: 0.5,
                    marginRight: '10px',
                  },
                  '& .icon': {
                    marginRight: '3px',
                  },
                })}
              >
                <Typography variant="inherit" component="p">
                  <DollarCircleSmall className="icon" />
                  <Box component="span">Starting at ${r.payRateLow.toLocaleString()}</Box>
                </Typography>
                <Typography variant="inherit" component="p">
                  <GuestsAltSmall className="icon" />
                  {r.minCapacity && r.minCapacity >= 1 ? (
                    <Box component="span">
                      {r.minCapacity.toLocaleString()} - {r.maxCapacity.toLocaleString()}
                    </Box>
                  ) : (
                    <Box component="span">Up to {r.maxCapacity}</Box>
                  )}
                </Typography>
              </Box>
            </Box>
          </StyledLink>
        </Grid>
      ))}
    </Grid>
  );
};

RelatedSpaces.propTypes = {
  relatedSpaces: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default RelatedSpaces;
