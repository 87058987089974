import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import { useDispatch } from 'react-redux';
import dynamic from 'next/dynamic';
import { Typography, Button } from '@tkww/the-bash-frontend';

import { Section, sectionPropTypes, sectionDefaultProps } from 'views/Profile/Sections';
import { AVAILABILITY_SECTION_ID, AVAILABILITY_SECTION_CONTACT_TEXT } from 'constants/Profiles';
import { gmConfig } from 'helpers/config';
import {
  profileCalendarContactCtaClick,
  profileCalendarNextMonthClick,
} from 'state/modules/analytics/profile';
import api from 'api/profiles';
import Bullet from 'components/Bullet';

const Calendar = dynamic(() => import('components/Calendar').then((module) => module.Calendar), {
  ssr: false,
});

const StyledButton = styled(Button)({
  marginBottom: 16,
});

// eslint-disable-next-line no-underscore-dangle
const _datesAreEqual = (date1, date2) =>
  date1.getFullYear() === date2.getFullYear() &&
  date1.getMonth() === date2.getMonth() &&
  date1.getDate() === date2.getDate();

let monthsDictionary = {};
const addMonthToDictionary = (date) => {
  if (!monthsDictionary[date.getFullYear()]) {
    monthsDictionary[date.getFullYear()] = [date.getMonth()];
  } else {
    monthsDictionary[date.getFullYear()].push(date.getMonth());
  }
};

let calendarEntries = [];

const getAvailabilityRestriction = (date) =>
  calendarEntries.find((e) => {
    const calendarDate = new Date(e.calendarDate);
    const hasRestriction = e.hasBookedEvent || e.isUnavailable;
    return _datesAreEqual(calendarDate, date) && hasRestriction;
  });

export const EXISTING_BOOKING_MESSAGE =
  'There is a booking on this date. Contact the vendor to confirm additional availability.';
export const UNAVAILABLE_DATE_MESSAGE = 'The vendor is unavailable on this date.';

const AVAILABILITY_RESTRICTION_TYPES = {
  EXISTING_BOOKING: 'EXISTING_BOOKING',
  UNAVAILABLE_DATE: 'UNAVAILABLE_DATE',
};

const AVAILABILITY_RESTRICTION_MESSAGE = {
  [AVAILABILITY_RESTRICTION_TYPES.EXISTING_BOOKING]: EXISTING_BOOKING_MESSAGE,
  [AVAILABILITY_RESTRICTION_TYPES.UNAVAILABLE_DATE]: UNAVAILABLE_DATE_MESSAGE,
};

const Availability = ({ profile, ...rest }) => {
  const { id, calendarEntries: initialCalendarEntries } = profile;
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [viewedMonth, setViewedMonth] = useState(new Date());
  const [availabilityRestrictionType, setAvailabilityRestrictionType] = useState('');
  const dispatch = useDispatch();

  const setAvailabilityRestrictionMessageForCalendarEntry = (calendarEntry) => {
    if (calendarEntry.isUnavailable) {
      setAvailabilityRestrictionType(AVAILABILITY_RESTRICTION_TYPES.UNAVAILABLE_DATE);
    } else if (calendarEntry.hasBookedEvent) {
      setAvailabilityRestrictionType(AVAILABILITY_RESTRICTION_TYPES.EXISTING_BOOKING);
    }
  };

  useEffect(() => {
    // Initial Profile loads current and next month
    const now = new Date();
    const fullYear = now.getFullYear();
    const currentMonth = now.getMonth();
    const nextMonth = new Date(now.getFullYear(), currentMonth + 1, 1).getMonth();

    monthsDictionary = {
      [fullYear]: [now.getMonth()],
    };

    // If next month is January, it means that we are moving into a new year
    if (nextMonth === 0) {
      monthsDictionary[fullYear + 1] = [nextMonth];
    } else {
      monthsDictionary[fullYear] = [nextMonth];
    }

    const calendarEntry = getAvailabilityRestriction(selectedDate);
    if (calendarEntry) {
      setAvailabilityRestrictionMessageForCalendarEntry(calendarEntry);
    }

    // Clean up global data
    return () => {
      monthsDictionary = {};
      calendarEntries = [];
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (calendarEntries.length === 0 && initialCalendarEntries) {
    calendarEntries = initialCalendarEntries;
  }

  const handleDateChange = (date) => {
    setSelectedDate(date);
    const calendarEntry = getAvailabilityRestriction(date);

    if (calendarEntry) {
      setAvailabilityRestrictionMessageForCalendarEntry(calendarEntry);
    } else if (availabilityRestrictionType) {
      setAvailabilityRestrictionType(null);
    }
  };

  const handleContactVendorClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(profileCalendarContactCtaClick(profile, selectedDate));
    window.location.assign(
      `https://${
        gmConfig.domains.www
      }/requestquote?memberId=${id}&eventDate=${selectedDate.toLocaleDateString('en-US')}`
    );
  };

  const handleOnMonthChange = async (newValue) => {
    setViewedMonth(newValue);
    dispatch(profileCalendarNextMonthClick(newValue));

    if (monthsDictionary[newValue.getFullYear()]?.includes(newValue.getMonth())) {
      return;
    }

    addMonthToDictionary(newValue);

    try {
      const newCalendarEntries = await api.getCalendarEntries(
        id,
        newValue.getMonth() + 1, // Month is 0 based
        newValue.getFullYear()
      );
      calendarEntries = calendarEntries.concat(newCalendarEntries || []);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const handleRenderDate = (day, selectedDates, pickersDayProps) => {
    const calendarEntry = calendarEntries.find((e) =>
      _datesAreEqual(new Date(e.calendarDate), day)
    );
    const date = selectedDates[0];
    const isInCurrentViewedMonth = day.getMonth() === viewedMonth.getMonth();
    const showBookedEventIcon = (isInCurrentViewedMonth && calendarEntry?.hasBookedEvent) || false;
    const isDateSelected = day.getTime() === date.getTime();
    const isDateUnavailable = (isInCurrentViewedMonth && calendarEntry?.isUnavailable) || false;

    return (
      <Box
        key={day}
        sx={(theme) => ({
          ...(isDateUnavailable
            ? {
                '& > button': {
                  textDecoration: 'line-through',
                  textDecorationColor: theme.palette.grey[400],
                  color: theme.palette.grey[600],
                  textDecorationThickness: 'from-font',
                },
              }
            : {}),
          ...(showBookedEventIcon
            ? {
                display: 'flex',
                flexWrap: 'wrap',
                alignItems: 'center',
                flexDirection: 'column',
              }
            : {}),
          ...(isDateSelected
            ? {
                '& *': {
                  color: theme.palette.dark.text,
                  textDecorationColor: `${theme.palette.dark.text}`,
                },
              }
            : {}),
        })}
        data-testid={`calendar-date-${day.getFullYear()}${day.getMonth()}${day.getDate()}`}
      >
        <PickersDay {...pickersDayProps} />
        {showBookedEventIcon && <Bullet sx={{ marginTop: '-16px', zIndex: 0 }} />}
      </Box>
    );
  };

  const isCTADisabled =
    !selectedDate ||
    availabilityRestrictionType === AVAILABILITY_RESTRICTION_TYPES.UNAVAILABLE_DATE;

  return (
    <Section
      {...rest}
      sectionName={AVAILABILITY_SECTION_ID}
      right={
        <Grid container spacing={0}>
          <Grid item xs={12} md={6} pt="12px" pb="12px">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Paper sx={{ overflow: 'hidden' }}>
                <Calendar
                  value={selectedDate}
                  onChange={handleDateChange}
                  onMonthChange={handleOnMonthChange}
                  handleRenderDate={handleRenderDate}
                  leftArrowButtonProps={{
                    'data-testid': 'previous-month-availability',
                    'aria-label': 'Previous Month Availability',
                  }}
                  rightArrowButtonProps={{
                    'data-testid': 'next-month-availability',
                    'aria-label': 'Next Month Availability',
                  }}
                />
              </Paper>
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} md={6} pt="12px" pb="12px" pl={{ xs: 0, md: 5 }}>
            <Typography
              variant="body1"
              sx={(theme) => ({
                fontFamily: theme.fonts.semibold,
                fontWeight: theme.fontWeights.regular,
                marginBottom: '6px',
              })}
            >
              Choose the Date of Your Event
            </Typography>
            <Typography
              data-testid="availability-selection"
              variant="body1"
              sx={{
                visibility: selectedDate ? 'visible' : 'hidden',
                marginBottom: 1,
              }}
            >
              Date Selected: {selectedDate?.toLocaleDateString('en-US')}
            </Typography>
            <StyledButton
              type="primary"
              onClick={handleContactVendorClick}
              disabled={isCTADisabled}
              data-testid="availability-cta"
            >
              {AVAILABILITY_SECTION_CONTACT_TEXT}
            </StyledButton>
            {availabilityRestrictionType && (
              <Typography variant="body1">
                {AVAILABILITY_RESTRICTION_MESSAGE[availabilityRestrictionType]}
              </Typography>
            )}
          </Grid>
        </Grid>
      }
    />
  );
};

Availability.propTypes = {
  ...sectionPropTypes,
  profile: PropTypes.shape({}).isRequired,
};

Availability.defaultProps = {
  ...sectionDefaultProps,
};

export default Availability;
