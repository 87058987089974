import React from 'react';
import MediaTabLabel from 'views/Profile/ProfileLayout/MediaTabLabel';
import { styled } from '@mui/material/styles';
import getVideoAudioMediaTabLabelProps from 'helpers/profiles/getVideoAudioMediaTabLabelProps';
import MUITabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

const StyledBaseTab = styled(Tab)(({ theme }) => ({
  display: 'inline-block',
  textTransform: 'inherit',
  color: theme.palette.text.primary,
  fontFamily: theme.fonts.regular,
  fontSize: 16,
  lineHeight: 1.25,
  letterSpacing: 'normal',
  opacity: 1,
}));

const StyledMediaTab = styled(StyledBaseTab)(({ theme }) => ({
  letterSpacing: '0.5px',
}));

const Tabs = ({ selectedTab, handleTabChange, profile, trackProfileTabClick }) => {
  const videoAudioMediaLabel = getVideoAudioMediaTabLabelProps(profile);

  return (
    <MUITabs
      id="tab-indicator"
      value={selectedTab}
      onChange={handleTabChange}
      sx={(theme) => ({
        borderBottom: `1px solid ${theme.palette.grey[400]}`,
        marginBottom: 3,
        '& .MuiTabs-indicator': {
          height: 3,
        },
      })}
    >
      <StyledBaseTab value={0} label="Overview" onClick={() => trackProfileTabClick('Overview')} />
      {profile.numberOfPhotos && (
        <StyledMediaTab
          value={1}
          label={<MediaTabLabel text="Photos" count={profile.numberOfPhotos} />}
          onClick={() => trackProfileTabClick('Photo')}
        />
      )}
      {(profile.numberOfVideos || profile.numberOfAudioTracks) && (
        <StyledMediaTab
          value={2}
          label={
            <MediaTabLabel text={videoAudioMediaLabel.text} count={videoAudioMediaLabel.count} />
          }
          onClick={() => trackProfileTabClick('Videos & Audio')}
        />
      )}
    </MUITabs>
  );
};

export default React.memo(Tabs);
