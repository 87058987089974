import React from 'react';
import { StarRating } from 'components/VendorRating';
import { getAverageRating, getNumberOfReviews } from 'helpers/vendor';

const getProfileReviewsDetails = (profile, sx = {}) => {
  const { rating, numberOfReviews } = profile;
  const shouldShowStarRating = rating > 0;
  const shouldShowReviews = numberOfReviews > 0;

  if (!shouldShowStarRating) return null;

  const icon = (iconProps) => <StarRating stars={rating} sx={sx} {...iconProps} />;
  const detail = () => (
    <>
      Avg <b>{getAverageRating(rating)}</b>
      {shouldShowReviews && (
        <>
          &nbsp;&#124;&nbsp;<b>{getNumberOfReviews(numberOfReviews)}</b>
        </>
      )}
    </>
  );
  return {
    icon,
    detail,
  };
};

export default getProfileReviewsDetails;
