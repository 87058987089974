import React from 'react';
import PropTypes from 'prop-types';

import RelatedSpaces from 'views/Profile/VenueProfilePage/RelatedSpaces';
import {
  RELATED_SPACES_SECTION_ID,
  RELATED_SPACES_SECTION_TITLE,
  RELATED_SPACES_SECTION_SUBTITLE,
} from 'constants/Profiles';
import { Section, sectionPropTypes, sectionDefaultProps } from 'views/Profile/Sections';

export const RelatedSpacesSection = ({ profile, ...rest }) => {
  if (!profile.relatedProfiles || !(profile.relatedProfiles.length > 0)) return null;

  return (
    <Section
      {...rest}
      title={RELATED_SPACES_SECTION_TITLE}
      subTitle={RELATED_SPACES_SECTION_SUBTITLE}
      sectionName={RELATED_SPACES_SECTION_ID}
      right={<RelatedSpaces relatedSpaces={profile.relatedProfiles} />}
    />
  );
};

RelatedSpacesSection.propTypes = {
  ...sectionPropTypes,
  profile: PropTypes.shape({}).isRequired,
};

RelatedSpacesSection.defaultProps = {
  ...sectionDefaultProps,
};

export default RelatedSpacesSection;
