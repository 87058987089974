export const TEAL_100 = '#EDFEFE';
export const TEAL_300 = '#76CAD4';
export const TEAL_500 = '#63C2CE';
export const TEAL_700 = '#3DB2C1';
export const TEAL_800 = '#086672';

export const ORANGE_700 = '#D44D0D';
export const ORANGE_500 = '#F26522';
export const ORANGE_300 = '#F3763A';
export const ORANGE_200 = '#FCE2D5';
export const ORANGE_100 = '#FFF2F2';

export const YELLOW_700 = '#ffcf00';

export const GREY_600 = '#3C3C3C';
export const GREY_500 = '#4F4F4F';
export const GREY_450 = '#797A7C';
export const GREY_400 = '#A1A1A1';
export const GREY_300 = '#D0D0D0';
export const GREY_200 = '#F0F0F0';
export const GREY_100 = '#F3F3F3';
export const GREY_50 = '#FCFCFC';
export const GREY_000 = '#F8F8F8';

export const WHITE = '#FFFFFF';
export const TRANSPARENT = 'rgba(0,0,0,0)';

export const BLUE_100 = '#b8d0ff';
export const BLUE_700 = '#03143d';

export const allColors = [
  TEAL_300,
  TEAL_500,
  TEAL_700,
  TEAL_800,
  ORANGE_700,
  ORANGE_500,
  ORANGE_300,
  ORANGE_200,
  ORANGE_100,
  GREY_600,
  GREY_500,
  GREY_400,
  GREY_300,
  GREY_200,
  GREY_100,
  GREY_50,
  WHITE,
  TRANSPARENT,
  YELLOW_700,
  BLUE_100,
  BLUE_700,
];
