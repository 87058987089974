import PropTypes from 'prop-types';
import React from 'react';
import { Link } from '@tkww/the-bash-frontend';
import { Box } from '@mui/material';
import { socialMediaCtaClick } from 'state/modules/analytics';

export const SocialMediaLinks = ({ classes, profile }) => {
  const sites = [
    {
      url: profile.venue.website,
      name: 'Website',
    },
    {
      url: profile.venue.facebook,
      name: 'Facebook',
    },
    {
      url: profile.venue.instagram,
      name: 'Instagram',
    },
    {
      url: profile.venue.twitter,
      name: 'Twitter',
    },
  ].filter((site) => site.url);

  const numSites = sites.length;

  return (
    <Box component="span" sx={{ marginTop: 1, display: 'block' }}>
      {sites.map((site, index) => (
        <Box
          component="span"
          sx={(theme) => ({
            fontSize: 16,
            lineHeight: 1.25,
            fontFamily: theme.fonts.regular,
          })}
          key={site.url}
        >
          <Link path={site.url} onClick={() => socialMediaCtaClick(profile, site.name)}>
            {site.name}
          </Link>
          {index + 1 < numSites && (
            <Box component="span" sx={(theme) => ({ color: theme.palette.grey[500] })}>
              {' '}
              •{' '}
            </Box>
          )}
        </Box>
      ))}
    </Box>
  );
};

SocialMediaLinks.propTypes = {
  profile: PropTypes.shape({}).isRequired,
};

export default SocialMediaLinks;
