import getProfileType from 'helpers/profiles/getProfileType';
import { VENUE_PROFILE } from 'constants/Profiles';
import { buildGmServiceTypeSearchLink, buildGmStateSearchLink } from 'helpers/buildGmSearch';

const getProfileBreadCrumbs = (profile, isProfileClickable = false) => {
  const profileType = getProfileType(profile);
  if (profileType === VENUE_PROFILE) {
    const {
      venue: {
        market: { displayName: marketDisplayName, fileName: marketFileName },
      },
    } = profile;
    return [
      {
        path: '/venues',
        nextPath: `/venues`,
        label: 'Event Venues',
      },
      {
        path: `/search/venue-${marketFileName}`,
        nextPath: '/search/[slug]',
        label: `${marketDisplayName} Event Venues`,
      },
      {
        path: `/search/venue-${marketFileName}?venueType=${profile.primarySearchPageLookupInfo.serviceType.fileName}`,
        nextPath: `/search/[slug]`,
        label: `${profile.primarySearchPageLookupInfo.serviceType.pluralForm}`,
        crawlable: false,
      },
      {
        label: profile.profileName,
        path: isProfileClickable ? profile.profileUrl : null,
        nextPath: isProfileClickable ? '/[dir1]/[dir2]' : null,
      },
    ];
  }

  const {
    primarySearchPageLookupInfo: { serviceType, location, searchString },
  } = profile;

  const vendorBreadCrumbs = [];

  if (serviceType.isSingleSearchPage) {
    const searchLink = buildGmServiceTypeSearchLink({
      serviceType,
      searchCategoryId: serviceType.id,
    });

    vendorBreadCrumbs.push({
      path: searchLink,
      nextPath: '/search/[slug]',
      label: serviceType.pluralForm,
    });
  } else {
    const serviceTypeLink = buildGmServiceTypeSearchLink({
      serviceType,
      searchCategoryId: serviceType.id,
    });

    vendorBreadCrumbs.push({
      path: serviceTypeLink,
      nextPath: serviceTypeLink,
      label: serviceType.pluralForm,
    });

    const locationLink = buildGmStateSearchLink({
      serviceType,
      searchLocation: location,
    });

    vendorBreadCrumbs.push({
      path: locationLink,
      nextPath: '/search/[slug]',
      label: `${location.stateOrProvince.displayName} ${serviceType.pluralForm}`,
    });

    vendorBreadCrumbs.push({
      label: `${location.displayName} ${serviceType.pluralForm}`,
      path: `/search/${searchString}`,
      nextPath: '/search/[slug]',
    });
  }

  vendorBreadCrumbs.push({
    label: profile.profileName,
    path: isProfileClickable ? profile.profileUrl : null,
    nextPath: isProfileClickable ? '/[dir1]/[dir2]' : null,
  });

  return vendorBreadCrumbs;
};

export default getProfileBreadCrumbs;
