import React from 'react';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import isEmpty from 'lodash.isempty';
import { relatedProfileTileClick } from 'state/modules/analytics';
import RelatedProfileCard from './RelatedProfileCard';

export const RelatedProfileCards = ({ profile }) => {
  if (isEmpty(profile.relatedProfiles)) {
    return null;
  }

  return (
    <Grid container spacing={3} data-testid="related-profiles">
      {profile.relatedProfiles.map((account) => (
        <Grid item xs={12} md={6} lg={4} key={account.canonicalProfileDirectory}>
          <RelatedProfileCard profile={account} onDispatch={relatedProfileTileClick} />
        </Grid>
      ))}
    </Grid>
  );
};

RelatedProfileCards.propTypes = {
  profile: PropTypes.shape({
    relatedProfiles: PropTypes.array.isRequired,
    profileName: PropTypes.string.isRequired,
    thumbnailUrl: PropTypes.string.isRequired,
  }).isRequired,
};

export default RelatedProfileCards;
