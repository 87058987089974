import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { Link } from '@tkww/the-bash-frontend';

import {
  AttachmentIcon,
  ClockIcon,
  DollarCircleIcon,
  GuestsIcon,
  SquareFeetIcon,
} from 'components/Icons';
import { profileCtaClick } from 'state/modules/analytics';
import { ProfileDetails } from 'views/Profile';

const VenueDetails = ({
  profile,
  profile: {
    dateAdded,
    maxCapacity,
    minCapacity,
    payRateLow,
    sampleFloorPlanFileUrl,
    squareFootage,
    samplePackageFileUrl,
  },
}) => {
  const getDetails = () => {
    const details = [];

    if (payRateLow) {
      const icon = (iconProps) => <DollarCircleIcon {...iconProps} alt="Packages starting" />;
      const detail = () => (
        <>
          Packages Starting at <b>${payRateLow.toLocaleString()}</b>
        </>
      );
      details.push({ icon, detail });
    }

    if (maxCapacity) {
      const icon = (iconProps) => <GuestsIcon {...iconProps} />;
      const detail = () => (
        <>
          {minCapacity ? (
            <span>
              Between <b>{minCapacity.toLocaleString()}</b> and{' '}
              <b>{maxCapacity.toLocaleString()}</b> Guests
            </span>
          ) : (
            <span>
              Up to <b>{maxCapacity}</b> Guests
            </span>
          )}
        </>
      );
      details.push({ icon, detail });
    }

    if (squareFootage) {
      const icon = (iconProps) => <SquareFeetIcon {...iconProps} />;
      const detail = () => (
        <>
          <b>{squareFootage.toLocaleString()}</b> SQ Feet
        </>
      );
      details.push({ icon, detail });
    }

    if (dateAdded) {
      const icon = (iconProps) => <ClockIcon {...iconProps} alt="Date added" />;
      const detail = () => (
        <>
          Member Since <b>{dateAdded.substring(0, 4)}</b>
        </>
      );
      details.push({ icon, detail });
    }

    if (sampleFloorPlanFileUrl || samplePackageFileUrl) {
      const icon = (iconProps) => <AttachmentIcon {...iconProps} />;
      const detail = () => (
        <>
          {sampleFloorPlanFileUrl && (
            <Link
              path={sampleFloorPlanFileUrl}
              onClick={() => profileCtaClick(profile, 'Floor Plan')}
              type="standalone"
              target="_blank"
              rel="nofollow"
            >
              Floor Plan
            </Link>
          )}
          {sampleFloorPlanFileUrl && samplePackageFileUrl && (
            <Box
              component="span"
              sx={(theme) => ({
                color: theme.palette.text.light,
              })}
            >
              {' '}
              •{' '}
            </Box>
          )}
          {samplePackageFileUrl && (
            <Link
              path={samplePackageFileUrl}
              onClick={() => profileCtaClick(profile, 'Packages')}
              type="standalone"
              target="_blank"
              rel="nofollow"
            >
              Packages
            </Link>
          )}
        </>
      );
      details.push({ icon, detail });
    }

    return details;
  };

  return <ProfileDetails details={getDetails()} />;
};

VenueDetails.propTypes = {
  profile: PropTypes.shape({}).isRequired,
};

export default VenueDetails;
