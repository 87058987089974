export const SERVICES_PAGE_NAME = 'Services';

export const SEARCH_PAGE_NAME = 'Search Results';
export const SEARCH_DIRECTORY_PAGE_NAME = 'Vendor Directory';

export const PROFILE_PAGE_NAME = 'Profile';
export const PROFILE_REVIEWS_PAGE_NAME = 'Profile Reviews';

export const ALL_VENUES_PAGE_NAME = 'All Venues';
export const THEMES_HUB_PAGE_NAME = 'Theme Hub';

export const ARTICLES_TAG_PAGE_NAME = 'Article Tag';
export const ARTICLE_HUB_PAGE_NAME = 'Article Hub';
export const INDIVIDUAL_ARTICLE_PAGE_NAME = 'Article';

export const INDIVIDUAL_THEME_PAGE_NAME = 'Theme';

export const EVENTS_PAGE_NAME = 'Events';

export const POLICY_PAGE_NAME = 'Policy';
export const CONTACT_PAGE_NAME = 'Contact Us';
export const LOGIN_PAGE_NAME = 'Login';
export const RESET_PASSWORD_PAGE_NAME = 'Reset Password';

export const HOMEPAGE_NAME = 'Homepage';
export const ABOUT_PAGE_NAME = 'About';
export const UNSUBSCRIBE_LANDING_PAGE_NAME = 'Unsubscribe';
export const UNSUBSCRIBE_SUCCESS_PAGE_NAME = 'Unsubscribe Success';
export const RISING_STAR_PAGE_NAME = 'Rising Star';
export const GUARANTEE_PAGE_NAME = 'Guarantee';

export const FAQ_PAGE_NAME = 'FAQ';
