import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import isempty from 'lodash.isempty';

import {
  selectLoadMoreReviewsIsLoading,
  selectReviews,
  loadMoreReviews,
  sortReviews,
} from 'state/modules/profiles';
import { LoadMoreButton } from 'components/Buttons';
import { NUM_REVIEWS_TO_LOAD_MORE } from 'constants/Profiles';
import { profileLoadMoreCtaClick } from 'state/modules/analytics/profile';
import { REVIEW_SECTION_CLICK_AREA } from 'analytics/constants';
import Dropdown from 'components/Dropdown';
import { SORT_BY_OPTIONS, SORT_LABEL_BY_VALUE } from 'constants/reviews';
import { ctaClickEvent } from 'state/modules/analytics';
import ReviewCards from 'components/Reviews/ReviewCards';

const VendorReviews = ({ profile }) => {
  const [sortType, setSortType] = React.useState(null);
  const { profileUrl, numberOfReviews: totalReviews, id: profileId } = profile;
  const reviews = useSelector(
    (state) => selectReviews(state, profileUrl, null, null, sortType) || []
  );
  const loadingReviews = useSelector((state) => selectLoadMoreReviewsIsLoading(state, profileUrl));
  const dispatch = useDispatch();
  const reviewsLength = useRef();

  useEffect(() => {
    if (sortType === null) return;

    dispatch(
      sortReviews({
        directory: profileUrl,
        memberID: profileId,
        skip: 0,
        take: reviewsLength.current,
        sortType,
      })
    );
  }, [sortType]);

  if (isempty(reviews) && !loadingReviews) return null;

  const handleLoadMore = () => {
    dispatch(profileLoadMoreCtaClick(REVIEW_SECTION_CLICK_AREA));
    return loadMoreReviews({
      directory: profileUrl,
      memberID: profileId,
      skip: reviews.length,
      take: NUM_REVIEWS_TO_LOAD_MORE,
      sortType,
    });
  };
  const showLoadMore = reviews.length < totalReviews;

  const handleSortChange = (e) => {
    reviewsLength.current = reviews.length;
    const { value } = e.target;
    const trackValueLabel = SORT_LABEL_BY_VALUE[value];
    setSortType(value);
    dispatch(
      ctaClickEvent('Reviews', 'Reviews Sort', {
        name: 'Profile',
        ctaStyle: 'dropdown',
        dropdownSelection: trackValueLabel,
      })
    );
  };

  return (
    <>
      {reviews.length > 2 && (
        <Dropdown
          label="Sort"
          value={sortType}
          handleChange={handleSortChange}
          options={SORT_BY_OPTIONS}
          sx={{ mb: '35px' }}
        />
      )}
      <ReviewCards reviews={reviews} profile={profile} />
      <LoadMoreButton
        testId="load-more-reviews"
        onClick={handleLoadMore}
        hideLoadMore={!showLoadMore}
        isLoading={loadingReviews}
        sx={{
          display: 'flex',
          margin: 'auto',
          whiteSpace: 'nowrap',
        }}
      />
    </>
  );
};

VendorReviews.propTypes = {
  profile: PropTypes.shape({
    profileName: PropTypes.string.isRequired,
  }),
};

export default VendorReviews;
