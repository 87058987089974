import PropTypes from 'prop-types';
import React from 'react';
import { styled } from '@mui/material/styles';
import { Link, Typography } from '@tkww/the-bash-frontend';

const StyledLink = styled(Link)(({ theme }) => ({
  marginLeft: '5px',
  textTransform: 'uppercase',
  height: 'auto',
  ...theme.fontSizes[14],
  fontFamily: theme.fonts.semibold,
  fontWeight: theme.fontWeights.semibold,
  color: theme.palette.primary.main,
}));

const TruncatedTextSection = ({ charLimit, content, path }) => {
  const needsTruncation = content && content.length > charLimit;

  const ToggleButton = () => <StyledLink path={path}>View More</StyledLink>;

  return (
    <>
      <Typography
        variant="body2"
        data-testid="content"
        sx={(theme) => ({
          display: 'inline',
          ...theme.fontSizes[16],
          fontFamily: theme.fonts.light,
        })}
      >
        {needsTruncation ? `${content.slice(0, charLimit).trim()}...` : content}
      </Typography>
      {needsTruncation && <ToggleButton />}
    </>
  );
};

TruncatedTextSection.propTypes = {
  charLimit: PropTypes.number.isRequired,
  content: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
};

export default TruncatedTextSection;
