import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { Typography } from '@tkww/the-bash-frontend';

import { StarRating } from 'components/VendorRating';
import getNumberOfReviews from 'helpers/vendor/getNumberOfReviews';
import getResizeImageUrl from 'helpers/media/getResizeImageUrl';
import getSxAttributes from 'helpers/getSxAttributes';
import sxPropType from 'propTypes/sx';

const PROFILE_IMAGE_DIMS = {
  height: 110,
  width: 110,
};

export const RelatedProfileCard = ({ profile, onDispatch, sx }) => {
  const {
    stars,
    thumbnailUrl,
    profileName,
    numberOfReviews,
    primaryServiceType,
    cityState,
    canonicalProfileDirectory,
  } = profile;
  const dispatch = useDispatch();

  const generateServiceAndLocation = () => {
    // generate service type and truncate
    const primaryService =
      primaryServiceType && primaryServiceType.personForm
        ? `${primaryServiceType.personForm.trim()}`
        : '';
    const primaryServiceStaticText = primaryService && cityState.trim().length > 1 ? ' in ' : '';
    const primaryServiceLocation = cityState.trim().length > 1 ? cityState : '';
    // concats all three
    return `${primaryService} ${primaryServiceStaticText} ${primaryServiceLocation}`;
  };

  const clickHandler = () => {
    if (onDispatch) {
      dispatch(onDispatch(profile));
    }
  };

  return (
    <Card
      sx={(theme) => ({
        border: '1px solid #D9DAE0',
        boxShadow: theme.constants.boxShadows.main,
        borderRadius: '3px',
        width: '100%',
        overflow: 'hidden',
        '&:hover, &:active': {
          boxShadow: theme.constants.boxShadows.darker,
        },
        ...getSxAttributes(theme, sx),
      })}
      data-testid="related-profile-item"
    >
      <CardActionArea
        href={canonicalProfileDirectory}
        onClick={() => clickHandler()}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          '&:hover': {
            backgroundColor: 'none',
            color: 'black',
            textDecoration: 'none',
          },
          '&:hover $focusHighlight': {
            opacity: 0,
          },
        }}
      >
        <CardMedia
          sx={{
            width: PROFILE_IMAGE_DIMS.width,
            height: PROFILE_IMAGE_DIMS.height,
            borderRadius: '50%',
            margin: '0 auto',
            marginTop: 2.5,
          }}
          image={getResizeImageUrl(
            thumbnailUrl,
            PROFILE_IMAGE_DIMS.width,
            PROFILE_IMAGE_DIMS.height
          )}
          title={profileName}
        />
        <CardContent
          sx={{
            margin: '0 auto',
            padding: 2,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: { xs: 'flex-end', md: 'flex-start' },
            height: { md: 180 },
          }}
        >
          <Typography
            variant="h4"
            component="p"
            sx={{
              lineHeight: 1.25,
              textTransform: 'none',
              margin: 0,
              letterSpacing: 0,
              textAlign: 'center',
              display: '-webkit-box' /* start of line clamp for 2 lines */,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              WebkitBoxOrient: 'vertical',
              maxHeight: 50 /* Fallback for non-webkit 16px SofiaPro-Semibold font @ line-height = 1 */,
              WebkitLineClamp: { xs: 1, md: 2 },
              minHeight: { md: 50 },
            }}
          >
            {profileName}
          </Typography>

          {(primaryServiceType || cityState.trim().length > 1) && (
            <Typography
              gutterBottom
              component="p"
              variant="small"
              sx={{
                lineHeight: 1.2,
                margin: '8px auto 0',
                textAlign: 'center',
                textTransform: 'uppercase',
                display: '-webkit-box' /* start of line clamp for 2 lines */,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                WebkitBoxOrient: 'vertical',
                maxHeight:
                  '28px' /* Fallback for non-webkit 12px SofiaPro-Regular font @ line-height = 1 */,
                WebkitLineClamp: 2,
                height: { md: '28px' },
              }}
              data-testid="profile-servicetype"
            >
              {generateServiceAndLocation()}
            </Typography>
          )}

          {stars > 0 && (
            <StarRating stars={stars} sx={{ justifyContent: 'center', margin: '1rem auto 0' }} />
          )}

          <Typography
            component="p"
            variant="small"
            sx={{
              margin: '8px auto 0',
              textAlign: 'center',
              textTransform: 'uppercase',
            }}
            data-testid="profile-reviews"
          >
            {getNumberOfReviews(numberOfReviews)}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

RelatedProfileCard.propTypes = {
  profile: PropTypes.shape({
    stars: PropTypes.number,
    thumbnailUrl: PropTypes.string.isRequired,
    profileName: PropTypes.string.isRequired,
    numberOfReviews: PropTypes.number,
    primaryServiceType: PropTypes.shape({
      personForm: PropTypes.string,
    }),
    cityState: PropTypes.string,
    canonicalProfileDirectory: PropTypes.string.isRequired,
  }).isRequired,
  onDispatch: PropTypes.func,
  sx: sxPropType,
};

RelatedProfileCard.defaultProps = {
  onDispatch: null,
  sx: {},
};

export default RelatedProfileCard;
