import React from 'react';
import PropTypes from 'prop-types';
import LazyLoad from 'react-lazyload';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledImage = styled(Box, { component: 'img' })(() => ({
  width: '100%',
  height: '100%',
}));

const AwardIcon = (props) => {
  const { award, className, lazyload } = props;

  return (
    <Box sx={{ width: { xs: 100, md: 140 }, height: { xs: 100, md: 140 } }} className={className}>
      {lazyload && (
        <LazyLoad>
          <StyledImage component="img" src={award.svgUrl} alt={award.altText} />
        </LazyLoad>
      )}
      {!lazyload && <StyledImage component="img" src={award.svgUrl} alt={award.altText} />}
    </Box>
  );
};

AwardIcon.propTypes = {
  award: PropTypes.shape({
    year: PropTypes.string,
    name: PropTypes.string,
    imageIdentifier: PropTypes.string.isRequired,
    altText: PropTypes.string,
    howEarned: PropTypes.string,
    svgUrl: PropTypes.string.isRequired,
  }).isRequired,
  className: PropTypes.string,
  lazyload: PropTypes.bool,
};

AwardIcon.defaultProps = {
  className: '',
  lazyload: true,
};

export default AwardIcon;
