import isEmpty from 'lodash.isempty';

const getVideoAudioMediaTabLabelProps = profile => {
  if (isEmpty(profile)) {
    return {};
  }

  const count = profile.numberOfVideos + profile.numberOfAudioTracks;
  let label = profile.numberOfVideos ? 'Videos' : '';
  const concatenation = profile.numberOfVideos ? ' & ' : '';

  if (profile.numberOfAudioTracks) {
    label += `${concatenation}Audio`;
  }

  return {
    text: label,
    count,
  };
};

export default getVideoAudioMediaTabLabelProps;
