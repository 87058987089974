import PropTypes from 'prop-types';
import React, { useEffect, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import { Box, useMediaQuery } from '@mui/material';
import { Loader } from '@tkww/the-bash-frontend';

import MasonryLayout from 'components/MasonryLayout';
import { loadMorePhotos, selectPhotos, selectPhotosIsLoading } from 'state/modules/profiles';
import { NUM_PHOTOS_TO_LOAD_MORE } from 'constants/Profiles';
import { PHOTO_TYPES } from 'constants/photo';
import getResizeImageUrl from 'helpers/media/getResizeImageUrl';
import { WHITE } from 'styles/colors';

const MAX_MOBILE_PHOTO_WIDTH = 480;
const MAX_TABLET_PHOTO_WIDTH = 704;
const MAX_DESKTOP_PHOTO_WIDTH = 564;

export const Photos = ({ profileName, profileUrl, totalNumberOfPhotos }) => {
  const photos = useSelector((state) => selectPhotos(state, profileUrl)) || [];
  const isLoading = useSelector((state) => selectPhotosIsLoading(state, profileUrl));
  const dispatch = useDispatch();
  const imageRefs = useRef([]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const galleryPhotos = photos
    .filter((p) => p.type === PHOTO_TYPES.GALLERY)
    .sort((a, b) => a.sequence - b.sequence);
  // Need to account for other photo types in photos field such as profile
  const additionalPhotoTypes = photos.filter((p) => p.type !== PHOTO_TYPES.GALLERY).length;
  const areAllPhotosLoaded = totalNumberOfPhotos + additionalPhotoTypes === photos.length;

  const handleLoadMore = useCallback(
    () => loadMorePhotos(profileUrl, NUM_PHOTOS_TO_LOAD_MORE),
    [profileUrl]
  );

  const isTopOfElement = (el) => el.getBoundingClientRect().top <= window.innerHeight;

  const trackScrolling = useCallback(() => {
    let loadMoreImages = false;
    imageRefs.current.forEach((el) => {
      if (isTopOfElement(el)) {
        loadMoreImages = true;
      }
    });
    if (!isLoading && loadMoreImages) {
      dispatch(handleLoadMore());
    }
  }, [dispatch, isLoading, handleLoadMore]);

  useEffect(() => {
    if (!areAllPhotosLoaded) document.addEventListener('scroll', trackScrolling);
    return () => {
      document.removeEventListener('scroll', trackScrolling);
    };
  }, [trackScrolling, areAllPhotosLoaded, dispatch]);

  const trackImageRef = (ref, index) => {
    const photoIndexIdLeftColumn =
      galleryPhotos.length < NUM_PHOTOS_TO_LOAD_MORE
        ? 0
        : galleryPhotos.length - NUM_PHOTOS_TO_LOAD_MORE;
    const photoIndexIdRightColumn = photoIndexIdLeftColumn > 0 ? photoIndexIdLeftColumn - 1 : 0;
    if (index === photoIndexIdLeftColumn || index === photoIndexIdRightColumn) {
      imageRefs.current.push(ref);
      if (imageRefs.current.length > 2) {
        imageRefs.current.shift();
      }
    }
  };

  const mobileGalleryImageMediaQuery = `(max-width: ${theme.breakpoints.values.sm - 0.1}px)`;
  const tabletGalleryImageMediaQuery = `(max-width: ${
    theme.breakpoints.values.md - 0.1
  }px) and (min-width: ${theme.breakpoints.values.sm}px)`;
  const desktopGalleryImageMediaQuery = `(min-width: ${theme.breakpoints.values.md}px)`;

  return (
    <>
      <MasonryLayout gap={30} columns={isMobile ? 1 : 2}>
        {galleryPhotos.map((image, index) => (
          <Box position="relative" key={image.url}>
            <picture ref={(ref) => trackImageRef(ref, index)} data-testid="gallery-picture">
              <source
                srcSet={getResizeImageUrl(image.url, MAX_MOBILE_PHOTO_WIDTH, 'h', true)}
                media={mobileGalleryImageMediaQuery}
              />
              <source
                srcSet={getResizeImageUrl(image.url, MAX_TABLET_PHOTO_WIDTH, 'h', true)}
                media={tabletGalleryImageMediaQuery}
              />
              <source
                srcSet={getResizeImageUrl(image.url, MAX_DESKTOP_PHOTO_WIDTH, 'h', true)}
                media={desktopGalleryImageMediaQuery}
              />
              <Box
                component="img"
                sx={{ width: '100%', borderRadius: '5px' }}
                src={image.url}
                alt={`${profileName} - Gallery ${index + 1}`}
              />
            </picture>
            {image.caption && (
              <Box
                sx={{
                  bottom: 6,
                  width: '100%',
                  height: 56,
                  position: 'absolute',
                  background: 'rgba(0, 0, 0, 0.7)',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: WHITE,
                  textOverflow: 'ellipsis',
                }}
              >
                <Box
                  component="span"
                  sx={(theme) => ({
                    width: 300,
                    [theme.breakpoints.up('md')]: {
                      width: 500,
                    },
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'initial',
                    display: '-webkit-box',
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: 'vertical',
                    textAlign: 'center',
                  })}
                >
                  {image.caption}
                </Box>
              </Box>
            )}
          </Box>
        ))}
      </MasonryLayout>
      {isLoading && <Loader />}
    </>
  );
};

Photos.propTypes = {
  profileUrl: PropTypes.string.isRequired,
  profileName: PropTypes.string.isRequired,
  totalNumberOfPhotos: PropTypes.number.isRequired,
};

export default Photos;
