import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { Typography } from '@tkww/the-bash-frontend';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import isempty from 'lodash.isempty';

import { StarRating } from 'components/VendorRating';
import Bullet from 'components/Bullet';
import ExpandableSection from 'components/ExpandableSection';
import { TruncatedTextSection } from 'views/ProfileReviewsPage/WidgetReviewsPage/TruncatedTextSection';
import { gmConfig } from 'helpers/config';
import { reviewTypes } from 'constants/Profiles';
import ReviewSnippetJsonLD from 'components/JsonLD/ReviewSnippet';
import { REVIEW_SECTION_CLICK_AREA } from 'analytics/constants';

const TRUNCATED_TEXT_CHAR_LIMIT = 200;

const ReviewCard = ({
  review,
  profile,
  type,
  minimumReviewDate,
  hideRebuttal,
  hideLocation,
  ...restProps
}) => {
  const {
    roundedAverage,
    clientFirstname,
    clientLastNameAbbreviation,
    dateAdded,
    rebuttal,
    eventTypeName,
    text,
    gigRequestCity,
    gigRequestState,
  } = review;

  let profileUrl = '';
  let profileName = '';
  if (!isempty(profile)) {
    profileUrl = profile.profileUrl;
    profileName = profile.profileName;
  }

  const showRebuttal =
    type === reviewTypes.default && rebuttal && rebuttal.length > 0 && !hideRebuttal;
  const reviewText = text?.trim();
  const rebuttalText = rebuttal?.trim();
  const destinationUrl = `https://${gmConfig.domains.www}${profileUrl}`;
  const reviewDate = format(parseISO(dateAdded, new Date()), 'MMMM d, yyyy');
  const showReviewDate = minimumReviewDate ? dateAdded > minimumReviewDate : true;

  return (
    <>
      {!isempty(profile) && <ReviewSnippetJsonLD profile={profile} review={review} />}
      <Box data-testid="review-card" {...restProps}>
        {type !== reviewTypes.compact && (
          <>
            <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', marginBottom: 1 }}>
              <StarRating stars={roundedAverage} />
              <Typography variant="body1" sx={(theme) => ({ fontFamily: theme.fonts.semibold })}>
                Review by {clientFirstname} {clientLastNameAbbreviation}.
              </Typography>
            </Box>
            <Box data-testid="review-subheading" mb={2}>
              <Typography
                variant="body2"
                sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}
              >
                <Box
                  component="span"
                  sx={(theme) => ({ ...theme.fontSizes[16], fontFamily: theme.fonts.semibold })}
                >
                  {eventTypeName}
                </Box>
                {showReviewDate && (
                  <>
                    <Bullet />
                    Reviewed on {reviewDate}
                  </>
                )}
                {!hideLocation && (
                  <>
                    <Bullet />
                    From {gigRequestCity}, {gigRequestState}
                  </>
                )}
              </Typography>
            </Box>
          </>
        )}
        {type === reviewTypes.default && (
          <ExpandableSection fadeTruncatedText clickArea={REVIEW_SECTION_CLICK_AREA}>
            {reviewText}
            {showRebuttal && (
              <>
                <Box
                  sx={(theme) => ({
                    marginTop: '21px',
                    marginLeft: '13px',
                    width: 0,
                    height: 0,
                    borderLeft: '11px solid transparent',
                    borderRight: '11px solid transparent',
                    borderBottom: `18px solid ${theme.palette.grey[200]}`,
                  })}
                />
                <Box
                  sx={(theme) => ({
                    backgroundColor: theme.palette.grey[200],
                    padding: '17px 16px',
                  })}
                >
                  <Typography
                    variant="body1"
                    sx={(theme) => ({ marginBottom: 1, fontFamily: theme.fonts.semibold })}
                  >
                    {profileName}:
                  </Typography>
                  {rebuttalText}
                </Box>
              </>
            )}
          </ExpandableSection>
        )}
        {type !== reviewTypes.default && (
          <TruncatedTextSection
            content={reviewText}
            charLimit={TRUNCATED_TEXT_CHAR_LIMIT}
            path={destinationUrl}
          />
        )}
      </Box>
    </>
  );
};

ReviewCard.propTypes = {
  type: PropTypes.oneOf(Object.values(reviewTypes)),
  hideRebuttal: PropTypes.bool,
  hideLocation: PropTypes.bool,
  minimumReviewDate: PropTypes.string,
};

ReviewCard.defaultProps = {
  type: reviewTypes.default,
  hideRebuttal: false,
  hideLocation: false,
  minimumReviewDate: null,
};

export default ReviewCard;
