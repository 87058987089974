import React from 'react';
import PropTypes from 'prop-types';
import { JsonLD } from '@tkww/the-bash-frontend';

import { gmConfig } from 'helpers/config';

const BreadcrumbListJsonLD = ({ items }) => {
  const data = {
    '@context': 'http://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: items
      .filter((item) => item.crawlable !== false)
      .map(({ path, label: name }, index) => ({
        '@type': 'ListItem',
        position: index + 1,
        item: {
          name,
          id: path ? `https://${gmConfig.domains.www}${path}` : '',
          url: path ? `https://${gmConfig.domains.www}${path}` : '',
        },
      })),
  };

  return <JsonLD data={data} />;
};

BreadcrumbListJsonLD.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string,
      label: PropTypes.string,
    })
  ).isRequired,
};

export default BreadcrumbListJsonLD;
