import React from 'react';
import PropTypes from 'prop-types';

import { Typography } from '@tkww/the-bash-frontend';
import ExpandableSection from 'components/ExpandableSection';
import { ABOUT_SECTION_ID } from 'constants/Profiles';
import { Section, sectionPropTypes, sectionDefaultProps } from 'views/Profile/Sections';
import { ABOUT_SECTION_CLICK_AREA } from 'analytics/constants';

export const About = ({ profile, ...rest }) => {
  if (!profile.description) return null;

  return (
    <Section
      {...rest}
      sectionName={ABOUT_SECTION_ID}
      right={
        <ExpandableSection
          contentLength={profile.description.length}
          fadeTruncatedText
          clickArea={ABOUT_SECTION_CLICK_AREA}
        >
          <Typography variant="body1">{profile.description}</Typography>
        </ExpandableSection>
      }
    />
  );
};

About.propTypes = {
  ...sectionPropTypes,
  profile: PropTypes.shape({}).isRequired,
  onClickMore: PropTypes.func,
};

About.defaultProps = {
  ...sectionDefaultProps,
  onClickMore: () => {},
};

export default React.memo(About);
