import React from 'react';
import isEmpty from 'lodash.isempty';
import PropTypes from 'prop-types';
import isPremiumProfile from 'helpers/isPremiumProfile';
import { Box, Container } from '@mui/material';

import PremiumProfiles from 'components/PremiumProfiles';
import { PREMIUM_VENUES_SECTION_TITLE } from 'constants/Profiles';

export const VenuePremiumProfiles = ({ profile, ...rest }) => {
  if (isPremiumProfile(profile) || isEmpty(profile.premiumProfiles)) return null;

  return (
    <Box
      sx={(theme) => ({
        padding: '40px 0',
        backgroundColor: theme.palette.grey[200],
      })}
      role="complementary"
      aria-labelledby="premium-profiles"
    >
      <Container maxWidth="xl">
        <PremiumProfiles
          title={PREMIUM_VENUES_SECTION_TITLE}
          profiles={profile.premiumProfiles}
          lazyLoadThumbnails
        />
      </Container>
    </Box>
  );
};

VenuePremiumProfiles.propTypes = {
  profile: PropTypes.shape({}).isRequired,
};

export default VenuePremiumProfiles;
