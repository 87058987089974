import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import MuiBreadcrumbs from '@mui/material/Breadcrumbs';
import { styled } from '@mui/material/styles';
import { Link, Typography } from '@tkww/the-bash-frontend';

import BreadcrumbListJsonLD from 'components/JsonLD/BreadcrumbList';
import { breadcrumbCtaClick } from 'state/modules/analytics';

const StyledMuiBreadcrumbs = styled(MuiBreadcrumbs)(({ theme }) => ({
  '.MuiBreadcrumbs-root': {
    padding: '8px 0',
    marginBottom: 0,
  },
  '.MuiBreadcrumbs-li': {
    ...theme.fontSizes[12],
    fontFamily: theme.fonts.semibold,
    margin: 0,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  '.MuiBreadcrumbs-separator': {
    margin: '0 8px',
    lineHeight: '16px',
  },
  '&.mobileOnly': {
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  '&.desktopOnly': {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  ...theme.fontSizes[12],
  fontFamily: theme.fonts.semibold,
  margin: '0',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
}));

const MAX_ITEMS_AFTER_COLLAPSE = 3;

const BreadCrumbs = ({ items }) => {
  const dispatch = useDispatch();

  const handleBreadCrumbClick = (index) => {
    dispatch(breadcrumbCtaClick(index));
  };

  const breadcrumbs = items.map(({ label, path, ...other }, i) => {
    const testId = `breadcrumb-item-${i}`;
    if (path) {
      return (
        <Link
          path={path}
          {...other}
          data-testid={testId}
          key={label}
          onClick={() => handleBreadCrumbClick(i + 1)}
        >
          <StyledTypography
            variant="smallMenu"
            sx={{
              padding: '8px 0',
              marginBottom: 0,
            }}
          >
            {label}
          </StyledTypography>
        </Link>
      );
    }
    return (
      <StyledTypography data-testid={testId} key={label} variant="smallMenu">
        {label}
      </StyledTypography>
    );
  });

  return (
    <>
      <BreadcrumbListJsonLD items={items} />
      <StyledMuiBreadcrumbs
        data-testid="breadcrumbs"
        maxItems={MAX_ITEMS_AFTER_COLLAPSE}
        itemsAfterCollapse={MAX_ITEMS_AFTER_COLLAPSE}
        itemsBeforeCollapse={0}
        aria-label="breadcrumb"
        className="mobileOnly"
      >
        {breadcrumbs}
      </StyledMuiBreadcrumbs>
      <StyledMuiBreadcrumbs
        data-testid="breadcrumbs"
        maxItems={items.length}
        itemsAfterCollapse={items.length}
        itemsBeforeCollapse={0}
        aria-label="breadcrumb"
        className="desktopOnly"
      >
        {breadcrumbs}
      </StyledMuiBreadcrumbs>
    </>
  );
};

BreadCrumbs.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string,
      nextPath: PropTypes.string,
      label: PropTypes.string.isRequired,
      crawlable: PropTypes.bool,
    })
  ).isRequired,
};

export default React.memo(BreadCrumbs);
