import React from 'react';
import { Typography } from '@tkww/the-bash-frontend';
import getSxAttributes from 'helpers/getSxAttributes';
import sxPropType from 'propTypes/sx';

export const Bullet = ({ sx }) => (
  <Typography
    variant="small"
    sx={(theme) => ({
      marginLeft: 1,
      marginRight: 1,
      fontFamily: theme.fonts.bold,
      fontWeight: 'bold',
      ...getSxAttributes(theme, sx),
    })}
  >
    &bull;
  </Typography>
);

Bullet.propTypes = {
  sx: sxPropType,
};

Bullet.defaultProps = {
  sx: {},
};

export default Bullet;
