import React from 'react';
import PropTypes from 'prop-types';
import LazyLoad from 'react-lazyload';

import SVG from 'react-inlinesvg';
import { useTheme, styled } from '@mui/material/styles';

const StyledSvg = styled(SVG, {
  shouldForwardProp: (prop) => prop !== 'color' && prop !== 'hover',
})(({ color, hover }) => ({
  '& path': {
    fill: color,
  },
  '&:hover path': {
    fill: hover,
  },
}));

const COLORS = (theme) => ({
  white: { color: theme.palette.dark.text, hover: theme.palette.blue[100] },
  blue: { color: theme.palette.primary.main, hover: theme.palette.blue[500] },
});

const SocialIcon = ({ type, color, lazyLoad }) => {
  const theme = useTheme();
  const colors = COLORS(theme)[color];
  const source = `/images-next/social-links/${type}.svg`;

  return lazyLoad ? (
    <LazyLoad>
      <StyledSvg src={source} color={colors.color} hover={colors.hover} />
    </LazyLoad>
  ) : (
    <StyledSvg src={source} color={colors.color} hover={colors.hover} />
  );
};

SocialIcon.propTypes = {
  type: PropTypes.oneOf([
    'facebook',
    'facebook-white',
    'twitter',
    'link',
    'instagram',
    'pinterest',
    'tiktok',
  ]).isRequired,
  color: PropTypes.oneOf(['white', 'blue']).isRequired,
  lazyLoad: PropTypes.bool,
};

SocialIcon.defaultProps = {
  lazyLoad: true,
};

export default SocialIcon;
