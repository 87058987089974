import gmRedirect from 'api/gmRedirects';
import { gmConfig } from 'helpers/config';

const getRedirect = async (url) => {
  if (url && url.substring(1)) {
    const path = url.substring(1);
    const redirect = await gmRedirect.getRedirect(path);
    if (redirect) {
      const destination = redirect.replace(/%2f/gi, '/');
      // This must be the full domain, otherwise it only internally redirects within
      // the application.
      return `https://${gmConfig.domains.www}${destination}`;
    }
  }
  return null;
};
export const getRedirectDetails = async (url) => {
  const redirectPath = await getRedirect(url);

  if (redirectPath) {
    return getNextRedirect(redirectPath, true);
  }

  return null;
};

export const getNextRedirect = (destination, permanent) => ({
  redirect: { destination, permanent },
});
