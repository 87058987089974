import isNull from 'lodash.isnull';
import isUndefined from 'lodash.isundefined';

const buildSmartCroppedUrl = (url, width, height, widthStepSize, heightStepSize) => {
  if (isUndefined(width) || isUndefined(height)) return null;
  const widthMissing = isNull(width) || width === 0;
  const heightMissing = isNull(height) || height === 0;

  const outputWidth = !widthStepSize ? width : width + (widthStepSize - width % widthStepSize);
  const outputHeight = !heightStepSize
    ? height
    : height + (heightStepSize - height % heightStepSize);

  const modifierAppender = url.indexOf('~') === -1 ? '~' : '-';

  return widthMissing || heightMissing
    ? url
    : `${url}${modifierAppender}sc_${outputWidth}.${outputHeight}`;
};

export default buildSmartCroppedUrl;
