import React from 'react';
import PropTypes from 'prop-types';

import Icon from 'components/Icon';
import src from 'public/images-next/guests_alt_small.svg';

const GuestsAlt = ({ className }) => <Icon src={src} className={className} />;

GuestsAlt.propTypes = {
  className: PropTypes.string,
};

GuestsAlt.defaultProps = {
  className: '',
};

export default GuestsAlt;
