import React from 'react';
import dynamic from 'next/dynamic';
import { useDispatch } from 'react-redux';

import { PROFILE_SUMMARY_CLICK_AREA, STICKY_BAR_CLICK_AREA } from 'analytics/constants';
import searchApi from 'api/search';
import { CHECK_AVAILABILITY_TEXT } from 'constants/Venues';
import { ABOUT_SECTION_VENUE_TITLE, ABOUT_SECTION_VENUE_SUBTITLE } from 'constants/Profiles';

import isPremiumProfile from 'helpers/isPremiumProfile';

import { profilePropTypes, profileDefaultProps } from 'propTypes/profile';

import { useModalContext } from 'state/context/ModalProvider';
import { venueAvailabilityProfileClick, profileTabClick } from 'state/modules/analytics';

import { ProfileLayout } from 'views/Profile';
import {
  VenueServices,
  SocialMediaLinks,
  VenuePremiumProfiles,
  SimilarVenues,
  VenueDetails,
} from 'views/Profile/VenueProfilePage';
import { AboutSection, SummarySection, RelatedSpacesSection } from 'views/Profile/Sections';
import StickyFooter from 'components/Profile/StickyFooter';
import getProfileBreadCrumbs from 'helpers/profiles/getProfileBreadcrumbs';
import isEmpty from 'lodash.isempty';
import { Typography } from '@tkww/the-bash-frontend';

const VenueContactWizard = dynamic(() => import('components/VenueContactWizard'));

export const VenueProfilePage = (props) => {
  const { profile, serviceAttributes } = props;

  const dispatch = useDispatch();
  const trackProfileTabClick = (tab) => {
    if (profile.venue) {
      dispatch(profileTabClick(profile, tab));
    }
  };

  const { setOpen, setContent } = useModalContext();
  const onCheckAvailabilityClick = (isSticky = false) => {
    setContent(<VenueContactWizard venue={profile} />);
    setOpen(true);
    dispatch(
      venueAvailabilityProfileClick({
        clickArea: isSticky ? STICKY_BAR_CLICK_AREA : PROFILE_SUMMARY_CLICK_AREA,
        venueProfile: profile,
        venueId: profile.venue.id,
      })
    );
  };

  const trackPremiumProfileImpressions = () => {
    if (!isPremiumProfile(profile)) {
      const premiumProfileImpressionData = profile.premiumProfiles.map((premiumProfile, i) => ({
        memberId: premiumProfile.memberID,
        type: 'CompetitorProfile',
        serviceTypeId: premiumProfile.primaryServiceID,
        TotalResults: profile.premiumProfiles.count,
        searchPosition: i,
        Message: profile.slug,
      }));
      searchApi.trackImpressions(premiumProfileImpressionData);
    }
  };

  const Summary = React.forwardRef(
    /* eslint-disable prefer-arrow-callback */
    function VenueSummary(summaryProps, ref) {
      return (
        <SummarySection
          ref={ref}
          {...summaryProps}
          contactCtaText={CHECK_AVAILABILITY_TEXT}
          details={<VenueDetails profile={profile} />}
          profile={profile}
          onContactClick={onCheckAvailabilityClick}
        />
      );
    }
  );

  const StickyFooterComponent = React.forwardRef(
    /* eslint-disable prefer-arrow-callback */
    function VenueStickyFooter(footerProps, ref) {
      return (
        <StickyFooter
          ref={ref}
          {...footerProps}
          profile={profile}
          contactCtaText={CHECK_AVAILABILITY_TEXT}
          onClick={onCheckAvailabilityClick}
          details={
            <Typography textTransform="none">
              {profile.primaryServiceType.name} in {profile.city.displayName}
            </Typography>
          }
        />
      );
    }
  );

  const displayPremiumProfiles = !isPremiumProfile(profile) || isEmpty(profile.premiumProfiles);

  return (
    <ProfileLayout
      {...props}
      breadcrumbs={getProfileBreadCrumbs(profile)}
      footer={
        <>
          {displayPremiumProfiles && <VenuePremiumProfiles profile={profile} />}
          <SimilarVenues profile={profile} addTopDivider={!displayPremiumProfiles} />
        </>
      }
      trackImpressions={trackPremiumProfileImpressions}
      contactCtaText={CHECK_AVAILABILITY_TEXT}
      onCheckAvailability={onCheckAvailabilityClick}
      summary={Summary}
      stickyFooter={StickyFooterComponent}
      trackProfileTabClick={trackProfileTabClick}
    >
      <AboutSection
        profile={profile}
        title={ABOUT_SECTION_VENUE_TITLE}
        subTitle={ABOUT_SECTION_VENUE_SUBTITLE}
        left={isPremiumProfile(profile) && <SocialMediaLinks profile={profile} />}
      />
      <VenueServices profile={profile} serviceAttributes={serviceAttributes} />
      <RelatedSpacesSection profile={profile} />
    </ProfileLayout>
  );
};

VenueProfilePage.propTypes = profilePropTypes;
VenueProfilePage.defaultProps = profileDefaultProps;

export default VenueProfilePage;
