import PropTypes from 'prop-types';
import React from 'react';
import { Typography } from '@tkww/the-bash-frontend';
import { Box } from '@mui/material';

import { getCapacityText } from 'helpers/venues';

import DollarCircle from 'components/Icons/DollarCircle';
import GuestsAlt from 'components/Icons/GuestsAlt';

import Image from 'components/Image';
import Link from 'components/Link';
import getDisplayTextWithLengthLimit from 'helpers/getDisplayTextWithLengthLimit';
import { dropShadow } from 'styles/classes';
import classnames from 'classnames';
import sxPropType from 'propTypes/sx';

const PremiumProfileTile = (props) => {
  const { profile, lazyLoadThumbnail, onClick, className } = props;

  return (
    <Box
      sx={(theme) => ({
        '& .link': {
          ...dropShadow,
          width: '100%',
          color: 'inherit',
          backgroundColor: theme.palette.background.default,
          borderRadius: '3px',
          boxSizing: 'border-box',
          border: '1px solid transparent',
          maxWidth: { md: 'calc(50% - 15px)', xl: 'calc(25% - 22.5px)' },
          '&:hover': {
            color: 'inherit',
            textDecoration: 'none',
            border: `1px solid ${theme.palette.primary.main}`,
          },
        },
      })}
    >
      <Link
        className={classnames(['link', className])}
        testid="profile-link"
        href="/[dir1]/[dir2]"
        as={profile.profileUrl}
        onClick={onClick}
      >
        <Image
          sx={{
            display: 'block',
            width: '100%',
            // removes 1 pixels to account for border
            height: { xs: 344, md: 254 },
            borderRadius: '3px 3px 0 0',
            objectFit: 'cover',
          }}
          lazyLoad={lazyLoadThumbnail}
          fallback
          src={profile.thumbnailPhotoUrl || ''}
          alt={profile.shortenedDescription}
        />
        <Box
          sx={(theme) => ({
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            // removes 1 pixels to account for border
            height: 151,
            boxSizing: 'border-box',
            padding: theme.spacing(2),
          })}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography
              variant="h4"
              sx={(theme) => ({
                margin: theme.spacing(0, 0, 1),
                height: 48,
                overflow: 'hidden',
              })}
            >
              {getDisplayTextWithLengthLimit(profile.profileName, 35)}
            </Typography>
            <Typography
              variant="small"
              sx={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}
            >
              {`${profile.primaryServiceName} in ${profile.cityName}, ${profile.stateOrProvinceAbbreviation}`}
            </Typography>
          </Box>
          <Box
            sx={(theme) => ({
              position: 'absolute',
              bottom: 24,
              display: 'flex',
              alignItems: 'center',
              '& > .icon': {
                marginRight: 0.5,
              },
              '& > .unit': {
                fontWeight: theme.fontWeights.bold,
                ...theme.fontSizes[16],
                textTransform: 'none',
                '&:not(:last-child)': {
                  marginRight: 2,
                },
              },
            })}
          >
            <DollarCircle className="icon" />
            <Typography variant="small" className="unit">
              {profile.payRangeLow ? profile.payRangeLow : 'Not Available'}
            </Typography>
            <GuestsAlt className="icon" />
            <Typography variant="small" className="unit">
              {getCapacityText(profile.minCapacity, profile.maxCapacity)}
            </Typography>
          </Box>
        </Box>
      </Link>
    </Box>
  );
};

PremiumProfileTile.propTypes = {
  profile: PropTypes.shape({}).isRequired,
  lazyLoadThumbnail: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  sx: sxPropType,
};

PremiumProfileTile.defaultProps = {
  sx: {},
};

export default PremiumProfileTile;
