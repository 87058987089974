import React, { useState, useEffect, useRef, useCallback } from 'react';
import isEmpty from 'lodash.isempty';
import { Box } from '@mui/material';

import { Typography } from '@tkww/the-bash-frontend';

import profilesApi from 'api/profiles';
import searchApi from 'api/search';

import BreadCrumbs from 'components/BreadCrumbs';
import SocialShare from 'components/SocialShare';
import SectionContainer from 'components/grid/SectionContainer';
import { PHOTO_TYPES } from 'constants/photo';
import PlannerValueProposition from 'components/PlannerValueProposition';

import jump from 'helpers/jump';
import { gmConfig } from 'helpers/config';

import { profileLayoutPropTypes, profileLayoutDefaultProps } from 'propTypes/profile';

import Tabs from './Tabs';
import Hero from './Hero';
import { MediaLayout, Photos, VideoAudio } from './MediaLayout';

export const ProfileLayout = ({
  breadcrumbs,
  defaultTab,
  profile,
  directory,
  children,
  footer,
  sponsoredListingId,
  onCheckAvailability,
  trackImpressions,
  summary,
  stickyFooter,
  trackProfileTabClick,
}) => {
  const ctaRef = useRef(null);
  const [selectedTab, setSelectedTab] = useState(defaultTab);

  const galleryPhotos = profile.photos.filter((p) => p.type === PHOTO_TYPES.GALLERY);
  const canonicalUrl = `https://${gmConfig.domains.www}${directory}`;

  const Summary = summary;
  const StickyFooter = stickyFooter;

  const jumpToMedia = useCallback(() => {
    if (profile.numberOfPhotos > 5) {
      setSelectedTab(1);
    } else if (!isEmpty(profile.memberMedia) || !isEmpty(profile.media)) {
      setSelectedTab(2);
    }

    jump('#tab-indicator');
  }, [profile]);

  const handleTabChange = useCallback((event, value) => {
    setSelectedTab(value);
  }, []);

  useEffect(() => {
    if (sponsoredListingId) {
      searchApi.trackImpressions([
        {
          memberId: profile.id,
          sponsoredListingId,
          sponsoredListingViewSource: 0,
          type: 'Profile',
        },
      ]);
    } else {
      profilesApi.trackImpressions(profile.id);
    }
    trackImpressions();
  }, [profile.id, trackImpressions]);

  const handleStickFooterClick = useCallback(() => onCheckAvailability(true), []);

  return (
    <>
      <SectionContainer sx={{ paddingBottom: 7 }}>
        <BreadCrumbs items={breadcrumbs} />
        <Hero profile={profile} onMediaButtonClick={jumpToMedia} />
        <Tabs
          profile={profile}
          selectedTab={selectedTab}
          handleTabChange={handleTabChange}
          trackProfileTabClick={trackProfileTabClick}
        />
        {selectedTab === 0 && (
          <>
            <Summary ref={ctaRef} />
            {children}
            <Box mb={7}>
              <Typography
                align="center"
                variant="h3"
                sx={(theme) => ({ marginBottom: 2, fontWeight: theme.fontWeights.regular })}
              >
                Share {profile.profileName}
              </Typography>
              <SocialShare
                sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                color="blue"
                description={profile.description}
                imageSrc={galleryPhotos[0] ? galleryPhotos[0].url : ''}
                url={canonicalUrl}
              />
            </Box>
            <Typography
              variant="small"
              sx={{ display: 'block', fontWeight: 100, textAlign: 'center' }}
            >
              Information displayed on this page is courtesy of {profile.profileName}
            </Typography>
            {!profile.venue && (
              <PlannerValueProposition
                categorySingular={profile.primaryServiceType.personForm}
                categoryPlural={profile.primaryServiceType.pluralForm}
                location={profile.city.displayName}
              />
            )}
          </>
        )}
        {selectedTab === 1 && (
          <MediaLayout label="Photos">
            <Photos
              profileName={profile.profileName}
              profileUrl={profile.profileUrl}
              totalNumberOfPhotos={profile.numberOfPhotos}
            />
          </MediaLayout>
        )}
        {selectedTab === 2 && (
          <MediaLayout label="Videos & Audio">
            <VideoAudio profileId={profile.id} media={profile.media} />
          </MediaLayout>
        )}
      </SectionContainer>

      {footer}

      <StickyFooter
        description={`${profile.primaryServiceType.name} in ${profile.city.displayName}`}
        onClick={handleStickFooterClick}
        ctaRef={ctaRef}
        alwaysStick={selectedTab !== 0}
      />
    </>
  );
};

ProfileLayout.propTypes = profileLayoutPropTypes;
ProfileLayout.defaultProps = profileLayoutDefaultProps;

export default React.memo(ProfileLayout);
