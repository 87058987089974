import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Button } from '@tkww/the-bash-frontend';

import { Section, sectionPropTypes, sectionDefaultProps } from 'views/Profile/Sections';
import { SUMMARY_SECTION_ID, VENDOR_PROFILE } from 'constants/Profiles';
import getProfileType from 'helpers/profiles/getProfileType';
import getProfileServiceTypeAndLocationText from 'helpers/profiles/getProfileServiceTypeAndLocationText';

const StyledSectionItem = styled(Grid)({
  paddingTop: 1.5,
  paddingBottom: 1.5,
});

const StyledButton = styled(Button)(({ theme }) => ({
  float: 'right',
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));

export const Summary = React.forwardRef(
  /* eslint-disable prefer-arrow-callback */
  function SummarySection({ contactCtaText, details, onContactClick, profile, ...rest }, ref) {
    const profileType = getProfileType(profile);

    let sectionSubtitle = null;
    let titleCaption = getProfileServiceTypeAndLocationText(profile);
    if (profileType === VENDOR_PROFILE) {
      sectionSubtitle = `Will travel up to ${profile.mileage} miles`;
    }

    const Details = details;

    return (
      <Section
        {...rest}
        title={profile.profileName}
        titleCaption={titleCaption}
        subTitle={sectionSubtitle}
        sectionName={SUMMARY_SECTION_ID}
        titleVariant="h1"
        right={
          <Grid container spacing={0} sx={{ padding: '12px 0' }}>
            <StyledSectionItem item xs={12} md={6}>
              {Details && Details}
            </StyledSectionItem>
            <StyledSectionItem item xs={12} md={6}>
              <Box ref={ref}>
                <StyledButton onClick={onContactClick}>{contactCtaText}</StyledButton>
              </Box>
            </StyledSectionItem>
          </Grid>
        }
        sxTitle={{
          textTransform: 'capitalize',
          margin: 0,
        }}
        sxSubtitle={{
          whiteSpace: 'pre-wrap',
        }}
      />
    );
  }
);

Summary.propTypes = {
  ...sectionPropTypes,
  contactCtaText: PropTypes.string.isRequired,
  details: PropTypes.node.isRequired,
  onContactClick: PropTypes.func.isRequired,
  profile: PropTypes.shape({}).isRequired,
};

Summary.defaultProps = {
  ...sectionDefaultProps,
};

export default Summary;
