import isempty from 'lodash.isempty';
import getProfileType from 'helpers/profiles/getProfileType';
import { profileDisplayNames, VENDOR_PROFILE, VENUE_PROFILE } from 'constants/Profiles';

const profilePageViewProps = (profile) => {
  if (!profile) return null;

  const profileType = getProfileType(profile);
  const profileTypeDisplayName = profileDisplayNames[profileType];

  function numAudio(numberOfAudioTracks) {
    if (numberOfAudioTracks === undefined) return 0;
    return numberOfAudioTracks;
  }

  const numberOfSongs = (songList) => songList?.length || 0;

  const numberOfRelatedProfiles = (relatedProfiles) => relatedProfiles?.length || 0;

  const numberOfAwards = (awards) => awards?.length || 0;

  let profileHasBlackoutDates = null;
  if (profileType === VENDOR_PROFILE) {
    profileHasBlackoutDates =
      !isempty(profile.calendarEntries) && profile.calendarEntries.some((e) => e.isUnavailable);
  }

  return {
    acceptsOnlinePayments: profileType === VENDOR_PROFILE ? profile.hasActiveGigPayAccount : null,
    availableFor: profileType === VENDOR_PROFILE ? profile.gigsDesired : null,
    availableForVirtualEvents:
      profileType === VENDOR_PROFILE ? profile.includeInVirtualEvents : null,
    avgStarRating: profileType === VENDOR_PROFILE ? profile.rating : null,
    guestMaxCapacity: profile.maxCapacity,
    guestMinCapacity: profile.minCapacity,
    numAwards: profileType === VENDOR_PROFILE ? numberOfAwards(profile.awards) : null,
    hasBlackoutDates: profileHasBlackoutDates,
    hasDescription: !isempty(profile.description),
    hasFloorPlan: profile.venue && !!profile.venue.sampleFloorPlanFileUrl,
    hasPackages: profile.venue && !!profile.venue.samplePackageFileUrl,
    hasRelatedSpaces: profileType === VENDOR_PROFILE ? null : !isempty(profile.associatedAccounts),
    numRelatedProfiles:
      profileType === VENDOR_PROFILE ? numberOfRelatedProfiles(profile.relatedProfiles) : null,
    numSongList: profileType === VENDOR_PROFILE ? numberOfSongs(profile.songList) : null,
    hasUpcomingEvents: profile.venue ? null : profile.upcomingEventTotal,
    hasPastEvents: profile.venue ? null : profile.pastEventTotal,
    hasWebsiteUrl: profile.venue && !!profile.venue.website,
    memberId: profile.id,
    memberTier: profile.membershipLevel,
    numAudio: profile.venue ? null : numAudio(profile.numberOfAudioTracks),
    numBookings: profileType === VENDOR_PROFILE ? profile.numberOfBookings || null : null,
    numPhotos: profile.numberOfPhotos === undefined ? 0 : profile.numberOfPhotos,
    numReviews: profileType === VENDOR_PROFILE ? profile.numberOfReviews : null,
    numVideos: profile.numberOfVideos === undefined ? 0 : profile.numberOfVideos,
    priceStartingAt: profile.payRateLow,
    priceStartingAtInterval: profileType === VENDOR_PROFILE ? profile.timePeriod : null,
    primaryCategory: profile.primaryServiceType.personForm,
    primaryCity: profile.city.name,
    primaryState: profile.city.stateOrProvince.name,
    profileName: profile.profileName,
    profileType: profileTypeDisplayName,
    associatedAccounts: profile.relatedProfiles,
    serviceAttributes: profile.serviceAttributes,
    servicesList: profileType === VENDOR_PROFILE ? profile.providedServiceTypes : null,
    squareFootage: profileType === VENUE_PROFILE ? profile.squareFootage : null,
    travelRadius: profileType === VENDOR_PROFILE ? profile.mileage : null,
    venueId: profile.venue && profile.venue.id,
    firstAiDescription: profile.firstAIAssistedDescriptionDate,
    mostRecentAiDescription: profile.lastAIAssistedDescriptionDate,
  };
};

export default profilePageViewProps;
