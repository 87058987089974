import isEmpty from 'lodash.isempty';

const buildPageAnalytics = (pageName, pageViewProps = null, pageLevelBaseProps = null) => {
  if (isEmpty(pageName)) {
    throw new Error('Page Name must be defined');
  }

  return {
    pageName,
    pageViewProps,
    pageLevelBaseProps: {
      ...pageLevelBaseProps,
      name: pageName,
    },
  };
};

export default buildPageAnalytics;
