import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import { BOOKED_EVENTS_SECTION_ID, NUM_EVENTS_TO_LOAD_MORE } from 'constants/Profiles';
import { Section, sectionPropTypes, sectionDefaultProps } from 'views/Profile/Sections';
import EventSection from 'views/Profile/Sections/BookedEvents/EventSection';
import {
  loadMorePastEvents,
  loadMoreUpcomingEvents,
  selectPastEvents,
  selectPastEventsIsLoading,
  selectUpcomingEvents,
  selectUpcomingEventsIsLoading,
} from 'state/modules/profiles';
import { profileLoadMoreCtaClick } from 'state/modules/analytics/profile';

const BookedEvents = ({ profile, ...rest }) => {
  const dispatch = useDispatch();
  const { upcomingEventTotal, pastEventTotal, profileUrl, id: profileId } = profile;

  const upcomingEvents = useSelector((state) => selectUpcomingEvents(state, profileUrl));
  const upcomingEventsIsLoading = useSelector((state) =>
    selectUpcomingEventsIsLoading(state, profileUrl)
  );
  const pastEvents = useSelector((state) => selectPastEvents(state, profileUrl));
  const pastEventsIsLoading = useSelector((state) => selectPastEventsIsLoading(state, profileUrl));

  const handleLoadMoreUpcomingEventsOnClick = () => {
    dispatch(profileLoadMoreCtaClick('Upcoming Events'));
    return loadMoreUpcomingEvents({
      directory: profileUrl,
      memberID: profileId,
      skip: upcomingEvents.length,
      take: NUM_EVENTS_TO_LOAD_MORE,
    });
  };

  const handleLoadMorePastEventsOnClick = () => {
    dispatch(profileLoadMoreCtaClick('Past Events'));
    return loadMorePastEvents({
      directory: profileUrl,
      memberID: profileId,
      skip: pastEvents.length,
      take: NUM_EVENTS_TO_LOAD_MORE,
    });
  };

  if (upcomingEventTotal === 0 && pastEventTotal === 0) return null;

  return (
    <Section
      {...rest}
      sectionName={BOOKED_EVENTS_SECTION_ID}
      right={
        <>
          <EventSection
            totalEvents={upcomingEventTotal}
            events={upcomingEvents}
            onClick={handleLoadMoreUpcomingEventsOnClick}
            title="Upcoming Events"
            isLoading={upcomingEventsIsLoading}
            noEventsText="No upcoming events"
          />
          <EventSection
            totalEvents={pastEventTotal}
            events={pastEvents}
            onClick={handleLoadMorePastEventsOnClick}
            title="Past Events"
            isLoading={pastEventsIsLoading}
          />
        </>
      }
    />
  );
};

BookedEvents.propTypes = {
  ...sectionPropTypes,
  profile: PropTypes.shape({}).isRequired,
};

BookedEvents.defaultProps = {
  ...sectionDefaultProps,
};

export default BookedEvents;
