import React from 'react';
import PropTypes from 'prop-types';
import { CaretIcon, Link, Typography } from '@tkww/the-bash-frontend';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

import { Section, sectionDefaultProps, sectionPropTypes } from 'views/Profile/Sections';
import VendorReviews from 'views/Profile/VendorProfilePage/VendorReviews';
import { StarRating } from 'components/VendorRating';
import { getAverageRating, getNumberOfReviews } from 'helpers/vendor';
import { NUM_REVIEWS_TO_VIEW_MORE, REVIEWS_SECTION_ID } from 'constants/Profiles';
import { useDispatch } from 'react-redux';
import { seeAllReviewsClick } from 'state/modules/analytics/profile';

const StyledLink = styled(Link)({ display: 'flex', alignItems: 'center' });

const Reviews = ({ profile, ...rest }) => {
  const dispatch = useDispatch();

  const handleAllReviewsClick = () => {
    dispatch(seeAllReviewsClick());
  };

  if (!profile.feedback || profile.feedback.length === 0) return null;

  const { rating, numberOfReviews } = profile;

  const viewAllReviewsUrl = `${profile.profileUrl}/feedback`;

  return (
    <Section
      {...rest}
      sectionName={REVIEWS_SECTION_ID}
      sxSubtitle={{ marginBottom: '42px' }}
      left={
        <Box
          sx={(theme) => ({
            fontFamily: theme.fonts.semibold,
            marginBottom: { xs: 3.5, md: 12 },
            letterSpacing: 1,
          })}
        >
          <StarRating stars={rating} sx={{ mb: 1.5 }} />
          <Typography
            variant="body1"
            sx={(theme) => ({
              fontFamily: theme.fonts.semibold,
              marginBottom: 1.5,
              letterSpacing: 1,
            })}
          >
            {getAverageRating(rating, true)}
            &nbsp;&#124;&nbsp;
            {getNumberOfReviews(numberOfReviews)}
          </Typography>
          {numberOfReviews > NUM_REVIEWS_TO_VIEW_MORE && (
            <>
              <StyledLink
                data-testid="see-all-reviews-link"
                path={viewAllReviewsUrl}
                nextPath="/[dir1]/[dir2]/feedback"
                type="standalone"
                onClick={handleAllReviewsClick}
              >
                See all {getNumberOfReviews(numberOfReviews)}
                <CaretIcon direction="right" />
              </StyledLink>
            </>
          )}
        </Box>
      }
      right={<VendorReviews profile={profile} />}
    />
  );
};

Reviews.propTypes = {
  ...sectionPropTypes,
  profile: PropTypes.shape({}).isRequired,
};

Reviews.defaultProps = {
  ...sectionDefaultProps,
};

export default Reviews;
