import PropTypes from 'prop-types';
import serviceAttribute from 'propTypes/serviceAttribute';
import childrenPropType from 'propTypes/children';

export const profileShape = PropTypes.shape({
  city: PropTypes.shape({
    displayName: PropTypes.string.isRequired,
  }),
  id: PropTypes.number.isRequired,
  photos: PropTypes.array.isRequired,
  primaryServiceType: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }),
  profileName: PropTypes.string.isRequired,
});

export const profilePropTypes = {
  defaultTab: PropTypes.number,
  profile: profileShape.isRequired,
  serviceAttributes: PropTypes.arrayOf(serviceAttribute),
};

export const profileDefaultProps = {
  defaultTab: 0,
  serviceAttributes: [],
};

export const profileLayoutPropTypes = {
  ...profilePropTypes,
  children: childrenPropType,
  footer: childrenPropType,
  onCheckAvailability: PropTypes.func.isRequired,
  trackImpressions: PropTypes.func,
  summary: PropTypes.elementType.isRequired,
  stickyFooter: PropTypes.elementType.isRequired,
  trackProfileTabClick: PropTypes.func,
  breadcrumbs: PropTypes.array.isRequired,
};

export const profileLayoutDefaultProps = {
  ...profileDefaultProps,
  children: null,
  footer: null,
  trackImpressions: () => {},
  trackProfileTabClick: () => {},
};

export default PropTypes.shape(profileShape);
