/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import Isvg from 'react-inlinesvg';
import { Box } from '@mui/material';

const Icon = (props) => {
  const { src, className, onClick } = props;

  return (
    <Box sx={{ verticalAlign: 'middle' }} component="span" className={className} onClick={onClick}>
      <Isvg src={src} cacheGetRequests />
    </Box>
  );
};

Icon.propTypes = {
  src: PropTypes.string.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func,
};

Icon.defaultProps = {
  className: '',
  onClick: () => {},
};

export default Icon;
